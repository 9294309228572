import React, { useEffect, useState } from "react";
import { Row, Col, Form, Modal, Button } from "react-bootstrap";
import { getEmailList, updateEmailId } from "../../Redux/actions/EmailAction";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";

const UpdateEmailModal = (props) => {
  const dispatch = useDispatch();
  const [updateEmail, setUpdateEmail] = useState("");
  const [updateEmailRole, setUpdateEmailRole] = useState("");
  const [updateMsg, setUpdateMsg] = useState(false);
  const emailUpdateResponse = useSelector((state) => state.updateEmail);
  useEffect(() => {
    if (props.useremail !== "") {
      setUpdateEmail(props.useremail.email);
      setUpdateEmailRole(props?.useremail?.role);
    }
  }, [props.useremail]);
  useEffect(() => {
    if (emailUpdateResponse.message !== undefined && updateMsg === true) {
      //console.log("inside", deleteUserResponse.message);
      updateSuccessAlert();
      dispatch(getEmailList());
      setUpdateMsg(false);
    }
    if (emailUpdateResponse.error !== undefined && updateMsg === true) {
      setUpdateMsg(false);
      alert("Something Went Wrong.Try Again Later");
    }
  }, [emailUpdateResponse]);
  const updateSuccessAlert = () => {
    toast.info(`Email Update Successfully`, {
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  };
  const updateEmailHnadle = (e) => {
    e.preventDefault();
    dispatch(
      updateEmailId(props.useremail.email, updateEmail, updateEmailRole)
    );
    setUpdateMsg(true);
    props.onHide();
  };
  //console.log(emailUpdateResponse);
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title className="ms-auto" id="contained-modal-title-vcenter">
          Update Email (SL No:{props.useremail.id})
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-4">
        <Form>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>
              <b>Email id</b>
            </Form.Label>
            <Form.Control
              type="email"
              placeholder="demo@email.com"
              value={updateEmail}
              onChange={(e) => setUpdateEmail(e.target.value)}
              required
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
            <Form.Label>
              <b>User Role</b>
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="Admin"
              value={updateEmailRole}
              onChange={(e) => setUpdateEmailRole(e.target.value)}
              required
            />
          </Form.Group>
        </Form>
        <Row className="mt-3 d-flex justify-content-center">
          <Col xl={4} lg={6} className="mb-2 mb-lg-0">
            <Button className="cancel-btn border-0" onClick={props.onHide}>
              Cancel
            </Button>
          </Col>
          <Col xl={4} lg={6} className="mb-2 mb-lg-0">
            <Button className="submit-btn border-0" onClick={updateEmailHnadle}>
              Update
            </Button>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default UpdateEmailModal;
