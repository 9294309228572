import {
  EMAIL_LIST_REQUEST,
  EMAIL_LIST_SUCCESS,
  EMAIL_LIST_FAILURE,
  CREATE_EMAIL_REQUEST,
  CREATE_EMAIL_SUCCESS,
  CREATE_EMAIL_FAILURE,
  DELETE_EMAIL_REQUEST,
  DELETE_EMAIL_SUCCESS,
  DELETE_EMAIL_FAILURE,
  UPDATE_EMAIL_REQUEST,
  UPDATE_EMAIL_SUCCESS,
  UPDATE_EMAIL_FAILURE,
} from "../Type.js";

export const emailListReducers = (state = { emailList: {} }, action) => {
  switch (action.type) {
    case EMAIL_LIST_REQUEST:
      return { getloading: true, ...state };
    case EMAIL_LIST_SUCCESS:
      return { getloading: false, emailList: action.payload };
    case EMAIL_LIST_FAILURE:
      return { getloading: false, getListError: action.payload };
    default:
      return state;
  }
};

export const createEmailReducer = (state = {}, action) => {
  switch (action.type) {
    case CREATE_EMAIL_REQUEST:
      return { loading: true };
    case CREATE_EMAIL_SUCCESS:
      return {
        loading: false,
        message: action.payload,
      };
    case CREATE_EMAIL_FAILURE:
      return {
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const deleteEmailReducer = (state = {}, action) => {
  switch (action.type) {
    case DELETE_EMAIL_REQUEST:
      return { loading: true };
    case DELETE_EMAIL_SUCCESS:
      return {
        loading: false,
        message: action.payload,
      };
    case DELETE_EMAIL_FAILURE:
      return {
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const updateEmailReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_EMAIL_REQUEST:
      return { loading: true };
    case UPDATE_EMAIL_SUCCESS:
      return {
        loading: false,
        message: action.payload,
      };
    case UPDATE_EMAIL_FAILURE:
      return {
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
