import axios from "axios";
import {
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGIN_FAILURE,
  USER_LOGOUT,
  USER_LIST_REQUEST,
  USER_LIST_SUCCESS,
  USER_LIST_FAILURE,
  USER_ROLE_LIST_REQUEST,
  USER_ROLE_LIST_SUCCESS,
  USER_ROLE_LIST_FAILURE,
  USER_CREATE_REQUEST,
  USER_CREATE_SUCCESS,
  USER_CREATE_FAILURE,
  USER_DELETE_REQUEST,
  USER_DELETE_SUCCESS,
  USER_DELETE_FAILURE,
  USER_UPDATE_REQUEST,
  USER_UPDATE_SUCCESS,
  USER_UPDATE_FAILURE,
  ALL_USER_LIST_REQUEST,
  ALL_USER_LIST_SUCCESS,
  ALL_USER_LIST_FAILURE,
} from "../Type.js";

export const login = (username, password) => async (dispatch) => {
  try {
    dispatch({
      type: USER_LOGIN_REQUEST,
    });

    let form_data = new FormData();
    form_data.append("username", username);
    form_data.append("password", password);

    const { data } = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/api/auth/login/?perpose=can_login_web`,
      form_data
    );

    dispatch({
      type: USER_LOGIN_SUCCESS,
      payload: data,
    });

    localStorage.setItem("access_token", data.access_token);
  } catch (error) {
    dispatch({
      type: USER_LOGIN_FAILURE,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const logout = () => (dispatch) => {
  const config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("access_token")}`,
    },
  };
  axios.get(`${process.env.REACT_APP_BASE_URL}/api/auth/logout/`, config);
  // .then((res) => {
  //   console.log(res);
  // });
  localStorage.removeItem("access_token");
  localStorage.removeItem("url");

  dispatch({ type: USER_LOGOUT });
};

export const getUserList = (limit, offset) => async (dispatch) => {
  try {
    dispatch({
      type: USER_LIST_REQUEST,
    });
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    };
    const data = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/api/user/list/?limit=${limit}&offset=${offset}`,
      config
    );

    dispatch({
      type: USER_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: USER_LIST_FAILURE,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const createUserAction =
  (userName, email, contactNumber, password, userRole) => async (dispatch) => {
    try {
      dispatch({
        type: USER_CREATE_REQUEST,
      });
      const body =
        contactNumber !== ""
          ? {
              username: userName,
              email: email,
              contact_number: contactNumber,
              password: password,
              roles: userRole,
            }
          : {
              username: userName,
              email: email,
              //contact_number: contactNumber,
              password: password,
              roles: userRole,
            };
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      };
      //console.log(contactNumber);
      const { data } = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/auth/register/`,
        body,
        config
      );

      dispatch({
        type: USER_CREATE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: USER_CREATE_FAILURE,
        payload:
          error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message,
      });
    }
  };

export const deleteUser = (id) => async (dispatch) => {
  try {
    dispatch({
      type: USER_DELETE_REQUEST,
    });

    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        "Content-Type": "application/json",
      },
    };
    const { data } = await axios.delete(
      `${process.env.REACT_APP_BASE_URL}/api/user/UserDelete/${id}/`,
      config
    );
    dispatch({
      type: USER_DELETE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: USER_DELETE_FAILURE,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const updateUser = (id, body) => async (dispatch) => {
  try {
    dispatch({
      type: USER_UPDATE_REQUEST,
    });
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    };
    const { data } = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/api/user/change/${id}/`,
      body,
      config
    );

    dispatch({
      type: USER_UPDATE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: USER_UPDATE_FAILURE,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const getUserRoleList = () => async (dispatch) => {
  try {
    dispatch({
      type: USER_ROLE_LIST_REQUEST,
    });
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    };
    const data = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/api/user/role/list/?limit=10&offset=0`,
      config
    );

    dispatch({
      type: USER_ROLE_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: USER_ROLE_LIST_FAILURE,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const getAllUserList = (limit) => async (dispatch) => {
  try {
    dispatch({
      type: ALL_USER_LIST_REQUEST,
    });
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    };
    const data = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/api/user/list/?limit=${limit}&offset=0`,
      config
    );

    dispatch({
      type: ALL_USER_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: ALL_USER_LIST_FAILURE,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
