import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Sidebar from "../Sidebar/Sidebar";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import UpdateProductModal from "../PopupModal/UpdateProductModal";
import DeleteModal from "../PopupModal/DeleteModal";
import {
  Container,
  Row,
  Col,
  Table,
  Form,
  Modal,
  Spinner,
  Button,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronRight,
  faEye,
  faPenAlt,
  faTrashCan,
  faAdd,
} from "@fortawesome/free-solid-svg-icons";
import pdfIcon from "../../Assets/icons/pdf.png";
import excelIcon from "../../Assets/icons/excel.png";
import filter from "../../Assets/icons/search.png";
import txtIcon from "../../Assets/icons/TXT.png";
import downArrow from "../../Assets/icons/action.png";
import "./Inventory.css";
import { useSelector, useDispatch } from "react-redux";
import { logout } from "../../Redux/actions/UserAction";
import {
  getProductList,
  importCsvProductAction,
  getAllProductList,
  uploadProductCsvAction,
} from "../../Redux/actions/InventroyAction.js";
import { ToastContainer, toast } from "react-toastify";
import ReactPaginate from "react-paginate";
import generatePDF from "../../services/pdfGenerator";
import { CSVLink } from "react-csv";
import XLSX from "xlsx";
import axios from "axios";

const AssetList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [modalShow, setModalShow] = useState(false);
  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const [importAssetModal, setImportAssetModal] = useState(false);

  const [searchVal, setSearchVal] = useState("");
  const [searchResult, setSearchResult] = useState("");
  const [selectedAsset, setSelectedAsset] = useState("");
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [offsetCall, setOffsetCall] = useState(0);
  const [assetList, setAssetList] = useState([]);
  const [exportData, setExportData] = useState(false);
  const [sideMenu, setSideMenu] = useState(true);
  const { loading, productList, error } = useSelector(
    (state) => state.getProductList
  );
  const importCsvResponse = useSelector((state) => state.importProductCsv);
  const uploadCsvResponse = useSelector(
    (state) => state.uploadProductCSVReducer
  );
  // const { loading, allProductList, allProductListError } = useSelector(
  //   (state) => state.getAllProductList
  // );
  const [filefield, setFilefield] = useState(null);

  //console.log(callData);
  useEffect(() => {
    dispatch(getProductList(limit, offset));
  }, [dispatch, offset]);
  //console.log(totalItems);
  useEffect(() => {
    if (productList?.data?.count) {
      setTotalItems(productList?.data?.count);
      //console.log(totalItems);
    }
  }, [productList]);

  useEffect(() => {
    if (error?.code === "token_not_valid") {
      errorAlert();
      dispatch(logout());
      navigate(0);
      //console.log("inside");
    }
  }, [error]);
  // useEffect(() => {
  //   if (
  //     totalItems !== 0 &&
  //     totalItems !== assetList.length &&
  //     importCsvResponse.loading !== true
  //   ) {
  //     //console.log(totalItems);
  //     getAllProducts();
  //   }
  // }, [totalItems]);
  useEffect(() => {
    if (searchVal.length !== 0 && assetList !== []) {
      const results = assetList.filter(
        (d) =>
          d.asset?.toString().toLowerCase().includes(searchVal) ||
          d.name?.toLowerCase().includes(searchVal) ||
          d.cost_center?.toLowerCase().includes(searchVal) ||
          d.sub_number?.toLowerCase().includes(searchVal) ||
          d.inventory_number?.toLowerCase().includes(searchVal) ||
          d.send_by?.toLowerCase().includes(searchVal) ||
          d.created_at?.toLowerCase().includes(searchVal)
      );
      setSearchResult(results);
    } else {
      //dispatch(getProductList(limit, 0));
      setSearchResult([]);
    }
  }, [searchVal]);

  useEffect(() => {
    if (importAssetModal && importCsvResponse.error !== undefined) {
      if (importCsvResponse.error?.detail?.non_field_errors) {
        alert(importCsvResponse.error.detail?.non_field_errors);
        //setImportAssetModal(false);
        console.log(importCsvResponse.error.detail);
      } else if (importCsvResponse.error.length !== 0) {
        if (importCsvResponse.error.message) {
          alert(importCsvResponse.error.message);
        } else if (importCsvResponse.error.csv_file) {
          alert(importCsvResponse.error.csv_file);
          console.log(importCsvResponse.error);
        } else if (importCsvResponse.error.non_field_errors) {
          alert(importCsvResponse.error.non_field_errors);
        } else if (importCsvResponse.error[0]?.asset) {
          alert(`Asset Id : ${importCsvResponse.error[0]?.asset[0]}`);
          //setImportAssetModal(false);
          //console.log(importCsvResponse.error[0].asset);
        } else if (importCsvResponse.error[0]?.cost_center) {
          alert(`Cost Center: ${importCsvResponse.error[0]?.cost_center[0]}`);
          //setImportAssetModal(false);
          //console.log(importCsvResponse.error[0].cost_center[0]);
        } else if (importCsvResponse.error[0]?.name) {
          alert(`Asset Name: ${importCsvResponse.error[0]?.name[0]}`);
          //setImportAssetModal(false);
          //console.log(importCsvResponse.error[0].name[0]);
        } else if (importCsvResponse.error[0]?.sub_number) {
          alert(`Sub Number: ${importCsvResponse.error[0]?.sub_number[0]}`);
          //setImportAssetModal(false);
          //console.log(importCsvResponse.error[0].sub_number[0]);
        } else if (importCsvResponse.error[0]?.inventory_number) {
          alert(
            `Inventory Number: ${importCsvResponse.error[0]?.inventory_number[0]}`
          );
          //setImportAssetModal(false);
          //console.log(importCsvResponse.error[0].sub_number[0]);
        } else if (importCsvResponse.error[0]?.department) {
          alert(`Department: ${importCsvResponse.error[0]?.department[0]}`);
          //setImportAssetModal(false);
          //console.log(importCsvResponse.error[0].sub_number[0]);
        } else {
          alert("Something Went Wrong");
        }
      } else {
        alert(importCsvResponse.error);
        //setImportAssetModal(false);
        console.log(importCsvResponse.error);
      }
    }
    if (importAssetModal && importCsvResponse.message !== undefined) {
      setImportAssetModal(false);
      successAlert();
      dispatch(getProductList(limit, offset));
      setFilefield(null);
    }
  }, [importCsvResponse]);
  // useEffect(() => {
  //   if (totalItems !== 0) {
  //     console.log("inside");
  //     getAllProducts();
  //   }
  // }, []);

  //console.log(assetList.length);
  const successAlert = () => {
    toast.info("Asset Added Successfully", {
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  };
  const errorAlert = () => {
    toast.error("Your Login Session Expired", {
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  };
  const importCsvFile = async () => {
    //console.log("file field", filefield);
    const form_data = new FormData();
    form_data.append("csv_file", filefield);
    dispatch(importCsvProductAction(form_data));
  };

  const handleFileUpload = (e) => {
    setFilefield(e.target.files[0]);
  };

  const handleFileSubmit = (e) => {
    e.preventDefault();
    if (filefield === null) {
      alert("Must Upload CSV File");
    }
    if (filefield !== null) {
      //setChange(!change);
      importCsvFile();
      //console.log(filefield);
    } else {
      //setChange(false);
      //console.log(filefield);
    }
    //console.log("inside Click");
  };
  const handleClickDelete = (user) => {
    setSelectedAsset(user);
    setDeleteModalShow(true);
  };

  const pageCount = Math.ceil(totalItems / limit);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * limit) % totalItems;
    setOffset(newOffset);
  };
  const getProductFromApi = (type) => {
    setExportData(true);
    try {
      //const form_data = new FormData();
      //form_data.append("csv_file", filefield);
      //const body = data;
      const settings = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      };
      console.log("inside Api Call");
      const api = `${process.env.REACT_APP_BASE_URL}/api/product/ns_list/?limit=${totalItems}&offset=0`;

      axios
        .get(api, settings)
        .then((res) => {
          //console.log("success", res.data.results);
          //setAssetList([...assetList, ...res.data.results]);
          if (type === "text") {
            downloadTextFile(res.data.results);
          } else if (type === "pdf") {
            setExportData(false);
            generatePDF(
              res.data.results,
              "Asset",
              [
                "Asset ID",
                "Sub-Number",
                "Inventory Number",
                "Cost Center",
                "Asset Name",
                "Department",
                "Created Date",
              ],
              "PUB Asset List",
              totalItems
            );
          } else if (type === "excel") {
            const test = [];
            res.data.results.map((d) => {
              let obj = {};
              obj["Asset"] = d.asset;
              obj["Sub Number"] = d.sub_number;
              obj["Inventory Number"] = d.inventory_number;
              obj["Cost Center"] = d.cost_center;
              obj["Name"] = d.name;
              obj["Department"] = d.department;
              test.push(obj);
            });
            //console.log(test);
            handleExport(test);
          }
          //setAssetList((prevData) => [...prevData, ...res.data.results]);
          //setImportAssetModal(false);
          //successAlert();
          //dispatch(getProductList());
          //setFilefield(null);
        })
        .catch((errror) => {
          console.log("error", errror);
          setExportData(false);
          alert("Something Went Wrong,Please Try Again After a minute");
        });
    } catch {
      console.log("error");
      setExportData(false);
      alert("Something Went Wrong,Please Try Again After a minute");
    }
  };
  const getAllProducts = () => {
    let offsetValue = 0;
    const totalCall = Math.ceil(totalItems / 500);
    for (let i = 0; i < totalCall; i++) {
      //console.log("inside");
      getProductFromApi(500, offsetValue);
      //await dispatch(getAllProductList(100, offsetValue));
      //setOffsetCall(100 + offsetCall);
      offsetValue = offsetValue + 500;
      //console.log(offsetValue);
    }
    //console.log(offsetCall);
    //console.log(limitCall);
  };
  const downloadTextFile = (array) => {
    if (array.length === 0) {
      alert("No Data Found");
      setExportData(false);
    } else if (array.length !== totalItems) {
      alert("Data is not ready for download . Try Again after a minute");
      setExportData(false);
    } else {
      const element = document.createElement("a");
      const file = new Blob(
        [
          "\t \t \t PUB ASSET LIST \n \n" +
            array
              .map(
                (arr) =>
                  arr.asset +
                  "\t" +
                  arr.sub_number +
                  "\t" +
                  arr.inventory_number +
                  "\t" +
                  arr.cost_center +
                  "\t" +
                  "\t" +
                  arr.name +
                  "\t" +
                  arr.department +
                  "\t"
              )
              .join("\n"),
        ],
        {
          type: "text/plain",
        }
      );
      element.href = URL.createObjectURL(file);
      element.download = "pub_asset_list.txt";
      document.body.appendChild(element); // Required for this to work in FireFox
      element.click();
      setExportData(false);
    }
  };
  const handleExport = (data) => {
    const sheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, sheet, "Sheet1");
    XLSX.writeFile(workbook, "PUB_ASSET_LIST.xlsx");
    setExportData(false);
  };

  const headers = [
    { label: "Asset Id", key: "asset" },
    { label: "Sub-Number", key: "sub_number" },
    { label: "Inventory-Number", key: "inventory_number" },
    { label: "Cost Center", key: "cost_center" },
    { label: "Asset Name", key: "name" },
  ];
  const csvReport = {
    data: assetList,
    headers: headers,
    filename: "PUB_ASSET_LIST.csv",
  };

  const postApi = async (data) => {
    try {
      //const form_data = new FormData();
      //form_data.append("csv_file", filefield);
      const body = data;
      const settings = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      };

      const api = "http://192.168.0.11:8000/api/product/create/";

      await axios
        .post(api, body, settings)
        .then((res) => {
          console.log("success", res.data);
          //setImportAssetModal(false);
          //successAlert();
          //dispatch(getProductList());
          //setFilefield(null);
        })
        .catch((errror) => {
          console.log("error", errror);
        });
    } catch {
      console.log("error");
    }
  };

  const handleSearchInput = (e) => {
    setSearchVal(e.target.value);
  };
  const handleCancelClick = () => {
    setImportAssetModal(false);
    setFilefield(null);
    window.location.reload();
  };

  //console.log(productList);
  //console.log(totalItems);
  //console.log(searchResult);
  return (
    <div>
      <UpdateProductModal
        show={modalShow}
        backdrop="static"
        keyboard={false}
        onHide={() => setModalShow(false)}
      />
      <DeleteModal
        show={deleteModalShow}
        onHide={() => setDeleteModalShow(false)}
        asset={selectedAsset}
        offset={offset}
        onAction={() => setSearchVal("")}
      />
      <Modal
        backdrop="static"
        keyboard={false}
        show={importAssetModal}
        onHide={() => setImportAssetModal(false)}
      >
        {/**<Modal.Header className="modal-customize-header" closeButton></Modal.Header> */}
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Import Assets as a Excel file</Form.Label>
              <label
                className="pt-4 pb-4"
                htmlFor="inputTag"
                style={{
                  cursor: "pointer",
                  display: "flex",
                  justifyContent: "center",
                  border: "1px solid #DCE0E4",
                  borderRadius: "5px",
                }}
              >
                <div className="d-flex flex-column justify-content-center align-items-center">
                  <span className="image-upload-text">+ Upload File</span>
                </div>
                <input
                  id="inputTag"
                  type={"file"}
                  //accept={".csv"}
                  style={{ display: "none" }}
                  required
                  onChange={handleFileUpload}
                />
              </label>
            </Form.Group>
            {filefield != null ? (
              <p>
                <b>Chosen file:</b> {filefield.name}
              </p>
            ) : (
              <p>
                <b>Chosen file:</b> No file chosen yet
              </p>
            )}
          </Form>
          {importCsvResponse.loading === true ? (
            <Row className="mt-2 d-flex justify-content-center">
              <Spinner animation="border" variant="primary" />
            </Row>
          ) : (
            <></>
          )}
          <Row className="mt-3">
            <div className="d-flex justify-content-center">
              <Button
                //type="submit"
                onClick={handleFileSubmit}
                variant="primary"
                //onClick={() => setImportAssetModal(false)}
              >
                Submit
              </Button>
              <Button
                className="ms-3"
                variant="secondary"
                onClick={handleCancelClick}
              >
                Cancel
              </Button>
            </div>
          </Row>
        </Modal.Body>
      </Modal>
      <Modal
        backdrop="static"
        keyboard={false}
        show={exportData}
        onHide={() => setExportData(false)}
      >
        {/**<Modal.Header className="modal-customize-header" closeButton></Modal.Header> */}
        <Modal.Body>
          {exportData === true ? (
            <Row className="mt-2 d-flex justify-content-center">
              <Spinner animation="border" variant="primary" />
              <p className="mt-3 text-center">
                <b>Data is Dowloading.Please Wait for a moment......</b>
              </p>
            </Row>
          ) : (
            <></>
          )}
        </Modal.Body>
      </Modal>
      <Sidebar setSideMenu={setSideMenu} sideMenu={sideMenu}></Sidebar>
      <div className="mainContent">
        <ToastContainer></ToastContainer>
        <Header setSideMenu={setSideMenu} sideMenu={sideMenu}></Header>
        <Container fluid>
          <Row className="ms-2 me-2">
            <Col lg={6}>
              <h4 className="product-heading">Asset List</h4>
              <p className="product-paragraph">
                {" "}
                Asset Management{" "}
                <FontAwesomeIcon icon={faChevronRight} size="xs" /> Asset List
              </p>
            </Col>
            <Col lg={6} className="d-flex justify-content-lg-end">
              <button
                className="pe-3 ps-3 border-0 add-product-button"
                onClick={() => setImportAssetModal(true)}
              >
                <FontAwesomeIcon
                  icon={faAdd}
                  style={{ paddingRight: "0.4rem" }}
                />
                Import Assets
              </button>
            </Col>
          </Row>
          <div
            className="mb-4 p-3 card product-card container-view"
            style={{ maxHeight: "700px" }}
          >
            <Row className="mb-4">
              <Col lg={6} className="d-none">
                <img src={filter} alt="filter" height={45} />
                <input
                  className="search-bar"
                  type="search"
                  placeholder="Search"
                  aria-label="Search"
                  value={searchVal}
                  onChange={handleSearchInput}
                />
              </Col>
              <Col lg={12} className="d-flex justify-content-end">
                <OverlayTrigger
                  key="export1"
                  placement="top"
                  overlay={<Tooltip id="tooltip-top">Export as a .txt</Tooltip>}
                >
                  <img
                    className="pe-3"
                    src={txtIcon}
                    alt="txt"
                    height={45}
                    onClick={() => getProductFromApi("text")}
                    style={{ cursor: "pointer" }}
                  />
                </OverlayTrigger>
                <OverlayTrigger
                  key="export2"
                  placement="top"
                  overlay={<Tooltip id="tooltip-top">Export as a pdf</Tooltip>}
                >
                  <img
                    className="pe-3"
                    src={pdfIcon}
                    alt="pdf"
                    height={45}
                    onClick={() => getProductFromApi("pdf")}
                    style={{ cursor: "pointer" }}
                  />
                </OverlayTrigger>
                <OverlayTrigger
                  key="export3"
                  placement="top"
                  overlay={
                    <Tooltip id="tooltip-top">Export as a excel</Tooltip>
                  }
                >
                  <img
                    src={excelIcon}
                    alt="pdf"
                    height={45}
                    onClick={() => getProductFromApi("excel")}
                    style={{ cursor: "pointer" }}
                  />
                </OverlayTrigger>
              </Col>
            </Row>
            <Table responsive hover>
              <thead className="text-center table-header">
                <tr>
                  {/**<th>
                    <Form.Check
                      type="checkbox"
                      id="checkbox"
                      onChange={handleSelectAll}
                      checked={isCheckAll}
                    />
                  </th> */}
                  <th>Asset Id</th>
                  <th>Sub-Number</th>
                  <th>Inventory Number</th>
                  <th>Cost Center</th>
                  <th>Asset Name</th>
                  <th>Department</th>
                  <th>Created Date</th>
                  <th>Actions</th>
                </tr>
              </thead>
              {searchVal.length === 0 &&
              productList !== {} &&
              productList?.data !== undefined ? (
                <tbody className="text-center">
                  {productList?.data?.results.map((product) => (
                    <tr key={product.id}>
                      <td>{product.asset}</td>
                      <td>{product.sub_number}</td>
                      <td>{product.inventory_number}</td>
                      <td>{product.cost_center}</td>
                      <td>{product.name}</td>
                      <td>{product.department}</td>
                      <td>{product.created_at.split("T")[0]}</td>
                      <td style={{ cursor: "pointer" }}>
                        <FontAwesomeIcon
                          className="d-none"
                          icon={faPenAlt}
                          color="#637381"
                          onClick={() => setModalShow(true)}
                        />
                        <OverlayTrigger
                          key="delete"
                          placement="top"
                          overlay={<Tooltip id="tooltip-top">Delete</Tooltip>}
                        >
                          <FontAwesomeIcon
                            className="delete-btn"
                            icon={faTrashCan}
                            color="#E40000"
                            onClick={() => handleClickDelete(product)}
                            //style={{ paddingLeft: "0.8rem" }}
                          />
                        </OverlayTrigger>
                      </td>
                    </tr>
                  ))}
                </tbody>
              ) : searchVal.length !== 0 && searchResult?.length !== 0 ? (
                <tbody className="text-center">
                  {searchResult?.map((product) => (
                    <tr key={product.id}>
                      <td>{product.asset}</td>
                      <td>{product.sub_number}</td>
                      <td>{product.inventory_number}</td>
                      <td>{product.cost_center}</td>
                      <td>{product.name}</td>
                      <td>{product.created_at.split("T")[0]}</td>
                      {/**<td>{product.createdTime}</td> */}
                      <td style={{ cursor: "pointer" }}>
                        <FontAwesomeIcon
                          className="d-none"
                          icon={faPenAlt}
                          color="#637381"
                          onClick={() => setModalShow(true)}
                        />
                        <OverlayTrigger
                          key="delete"
                          placement="top"
                          overlay={<Tooltip id="tooltip-top">Delete</Tooltip>}
                        >
                          <FontAwesomeIcon
                            icon={faTrashCan}
                            color="#E40000"
                            onClick={() => handleClickDelete(product)}
                            //style={{ paddingLeft: "0.8rem" }}
                          />
                        </OverlayTrigger>
                      </td>
                    </tr>
                  ))}
                </tbody>
              ) : loading === true ? (
                <tbody className="text-center">
                  <tr>
                    <td colSpan="12">
                      <Spinner animation="border" variant="primary" />
                    </td>
                  </tr>
                </tbody>
              ) : (
                <tbody className="text-center">
                  <tr>
                    <td colSpan="12">No Results Found</td>
                  </tr>
                </tbody>
              )}
            </Table>
            {pageCount > 1 && searchVal.length === 0 ? (
              <ReactPaginate
                breakLabel="..."
                nextLabel=""
                onPageChange={handlePageClick}
                pageRangeDisplayed={3}
                marginPagesDisplayed={1}
                pageCount={pageCount}
                previousLabel=""
                renderOnZeroPageCount={null}
                containerClassName="pagination"
                pageLinkClassName="page-num"
                previousClassName="hide"
                nextClassName="hide"
                activeLinkClassName="active"
              />
            ) : (
              <></>
            )}
          </div>
        </Container>
      </div>
      <Footer></Footer>
    </div>
  );
};

export default AssetList;

