import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import PrivateRoute from "./Routes/PrivateRoute";
import Login from "./Components/LoginPage/Login";
import Dashboard from "./Components/Dashboard/Dashboard";
import AssetList from "./Components/InventoryManagement/AssetList";
import AddAsset from "./Components/InventoryManagement/AddAsset";
import ScannedList from "./Components/InventoryManagement/ScannedList";
import UserList from "./Components/UserManagement/UserList";
import AddUser from "./Components//UserManagement/AddUser";
import EmailManagrment from "./Components/EmailManagement/EmailManagement";
import CostCenter from "./Components/CostCenter/CostCenter";
import NotFoundPage from "./Components/NotFoundPage/NotFoundPage";
import "./App.css";

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/login" element={<Login></Login>} />
        <Route
          path="/"
          element={
            <PrivateRoute>
              <Dashboard></Dashboard>
            </PrivateRoute>
          }
        />

        <Route
          path="/asset-list"
          element={
            <PrivateRoute>
              <AssetList></AssetList>
            </PrivateRoute>
          }
        />

        <Route
          path="/add-asset"
          element={
            <PrivateRoute>
              <AddAsset></AddAsset>
            </PrivateRoute>
          }
        />

        <Route
          path="/scanned-list"
          element={
            <PrivateRoute>
              <ScannedList></ScannedList>
            </PrivateRoute>
          }
        />

        {/* <Route
          path="/cost-center"
          element={
            <PrivateRoute>
              <CostCenter></CostCenter>
            </PrivateRoute>
          }
        ></Route> */}

        <Route
          path="/userList"
          element={
            <PrivateRoute>
              <UserList></UserList>
            </PrivateRoute>
          }
        />
        <Route
          path="/addUser"
          element={
            <PrivateRoute>
              <AddUser></AddUser>
            </PrivateRoute>
          }
        />

        <Route
          path="/email-management"
          element={
            <PrivateRoute>
              <EmailManagrment></EmailManagrment>
            </PrivateRoute>
          }
        ></Route>

        <Route path="*" element={<NotFoundPage></NotFoundPage>} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
