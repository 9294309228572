import {
  CREATE_COST_CENTER_REQUEST,
  CREATE_COST_CENTER_SUCCESS,
  CREATE_COST_CENTER_FAILURE,
  COST_CENTER_LIST_REQUEST,
  COST_CENTER_LIST_SUCCESS,
  COST_CENTER_LIST_FAILURE,
  PRODUCT_LIST_REQUEST,
  PRODUCT_LIST_SUCCESS,
  PRODUCT_LIST_FAILURE,
  CREATE_PRODUCT_REQUEST,
  CREATE_PRODUCT_SUCCESS,
  CREATE_PRODUCT_FAILURE,
  SCANNED_PRODUCT_LIST_REQUEST,
  SCANNED_PRODUCT_LIST_SUCCESS,
  SCANNED_PRODUCT_LIST_FAILURE,
  IMPORT_CSV_PRODUCT_REQUEST,
  IMPORT_CSV_PRODUCT_SUCCESS,
  IMPORT_CSV_PRODUCT_FAILURE,
  PRODUCT_DELETE_REQUEST,
  PRODUCT_DELETE_SUCCESS,
  PRODUCT_DELETE_FAILURE,
  ALL_PRODUCT_LIST_REQUEST,
  ALL_PRODUCT_LIST_SUCCESS,
  ALL_PRODUCT_LIST_FAILURE,
  ALL_SCANNED_PRODUCT_LIST_REQUEST,
  ALL_SCANNED_PRODUCT_LIST_SUCCESS,
  ALL_SCANNED_PRODUCT_LIST_FAILURE,
  DELETE_SCANNED_PRODUCT_REQUEST,
  DELETE_SCANNED_PRODUCT_SUCCESS,
  DELETE_SCANNED_PRODUCT_FAILURE,
  DELETE_ALL_SCANNED_PRODUCT_REQUEST,
  DELETE_ALL_SCANNED_PRODUCT_SUCCESS,
  DELETE_ALL_SCANNED_PRODUCT_FAILURE,
} from "../Type";

export const createCostCenterReducer = (state = {}, action) => {
  switch (action.type) {
    case CREATE_COST_CENTER_REQUEST:
      return { loading: true };
    case CREATE_COST_CENTER_SUCCESS:
      return {
        loading: false,
        message: action.payload,
      };
    case CREATE_COST_CENTER_FAILURE:
      return {
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const getCostCenterListReducers = (
  state = { costCenterList: {} },
  action
) => {
  switch (action.type) {
    case COST_CENTER_LIST_REQUEST:
      return { loading: true, ...state };
    case COST_CENTER_LIST_SUCCESS:
      return { loading: false, emailList: action.payload };
    case COST_CENTER_LIST_FAILURE:
      return { loading: false, getListError: action.payload };
    default:
      return state;
  }
};

export const getProductListReducers = (state = { productList: {} }, action) => {
  switch (action.type) {
    case PRODUCT_LIST_REQUEST:
      return { loading: true, ...state };
    case PRODUCT_LIST_SUCCESS:
      return { loading: false, productList: action.payload };
    case PRODUCT_LIST_FAILURE:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const createProductReducer = (state = {}, action) => {
  switch (action.type) {
    case CREATE_PRODUCT_REQUEST:
      return { loading: true };
    case CREATE_PRODUCT_SUCCESS:
      return {
        loading: false,
        message: action.payload,
      };
    case CREATE_PRODUCT_FAILURE:
      return {
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const imporCsvProductReducer = (state = {}, action) => {
  switch (action.type) {
    case IMPORT_CSV_PRODUCT_REQUEST:
      return { loading: true };
    case IMPORT_CSV_PRODUCT_SUCCESS:
      return {
        loading: false,
        message: action.payload,
      };
    case IMPORT_CSV_PRODUCT_FAILURE:
      return {
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const getScannedProductListReducers = (
  state = { scannedProductList: {} },
  action
) => {
  switch (action.type) {
    case SCANNED_PRODUCT_LIST_REQUEST:
      return { loading: true, ...state };
    case SCANNED_PRODUCT_LIST_SUCCESS:
      return { loading: false, scannedProductList: action.payload };
    case SCANNED_PRODUCT_LIST_FAILURE:
      return { loading: false, getListError: action.payload };
    default:
      return state;
  }
};

export const deleteProductReducer = (state = {}, action) => {
  switch (action.type) {
    case PRODUCT_DELETE_REQUEST:
      return { loading: true };
    case PRODUCT_DELETE_SUCCESS:
      return {
        loading: false,
        message: action.payload,
      };
    case PRODUCT_DELETE_FAILURE:
      return {
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const getAllProductListReducers = (
  state = { allProductList: {} },
  action
) => {
  switch (action.type) {
    case ALL_PRODUCT_LIST_REQUEST:
      return { loading: true, ...state };
    case ALL_PRODUCT_LIST_SUCCESS:
      return { loading: false, allProductList: action.payload };
    case ALL_PRODUCT_LIST_FAILURE:
      return { loading: false, allProductListError: action.payload };
    default:
      return state;
  }
};

export const getAllScannedProductListReducers = (
  state = { allScannedProductList: {} },
  action
) => {
  switch (action.type) {
    case ALL_SCANNED_PRODUCT_LIST_REQUEST:
      return { getloading: true, ...state };
    case ALL_SCANNED_PRODUCT_LIST_SUCCESS:
      return { getloading: false, allScannedProductList: action.payload };
    case ALL_SCANNED_PRODUCT_LIST_FAILURE:
      return { getloading: false, allScannedProductListError: action.payload };
    default:
      return state;
  }
};

export const uploadProductCSVReducer = (state = {}, action) => {
  switch (action.type) {
    case IMPORT_CSV_PRODUCT_REQUEST:
      return { loading: true };
    case IMPORT_CSV_PRODUCT_SUCCESS:
      return {
        loading: false,
        message: action.payload,
      };
    case IMPORT_CSV_PRODUCT_FAILURE:
      return {
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const deleteScannedProductReducer = (state = {}, action) => {
  switch (action.type) {
    case DELETE_SCANNED_PRODUCT_REQUEST:
      return { loading: true };
    case DELETE_SCANNED_PRODUCT_SUCCESS:
      return {
        loading: false,
        message: action.payload,
      };
    case DELETE_SCANNED_PRODUCT_FAILURE:
      return {
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const deleteAllScannedProductReducer = (state = {}, action) => {
  switch (action.type) {
    case DELETE_ALL_SCANNED_PRODUCT_REQUEST:
      return { loading: true };
    case DELETE_ALL_SCANNED_PRODUCT_SUCCESS:
      return {
        loading: false,
        message: action.payload,
      };
    case DELETE_ALL_SCANNED_PRODUCT_FAILURE:
      return {
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
