import jsPDF from "jspdf";
import "jspdf-autotable";

const generatePDF = (data, dataType, columnData, pdfTitle, totalItems) => {
  // initialize jsPDF
  if (data?.length === 0 || !data) {
    alert("No data Found");
  } else if (data?.length !== totalItems) {
    alert("Data is not ready for download . Try Again after a minute");
  } else {
    const doc = new jsPDF();

    // define the columns we want and their titles
    //const tableColumn = ["User ID", "Name", "Contact No", "Role", "Email"];
    // define an empty array of rows
    const tableRows = [];

    // for each ticket pass all its data into an array
    if (dataType === "Email") {
      data.forEach((each) => {
        const rowData = [
          each.id,
          each.email,
          each.role,
          // called date-fns to format the date on the ticket
          //format(new Date(ticket.updated_at), "yyyy-MM-dd"),
        ];
        // push each tickcet's info into a row
        tableRows.push(rowData);
      });
    } else if (dataType === "Asset") {
      data.forEach((each) => {
        const rowData = [
          each.asset,
          each.sub_number,
          each.inventory_number,
          each.cost_center,
          each.name,
          each.department,
          each.created_at.split("T")[0],
          // called date-fns to format the date on the ticket
          //format(new Date(ticket.updated_at), "yyyy-MM-dd"),
        ];
        // push each tickcet's info into a row
        tableRows.push(rowData);
      });
    } else if (dataType === "Scanned") {
      data.forEach((each) => {
        const rowData = [
          each.asset,
          each.sub_number,
          each.inventory_number,
          each.cost_center,
          each.name,
          each.department,
          each.send_by,
          each.created_at.split("T")[0],
          // called date-fns to format the date on the ticket
          //format(new Date(ticket.updated_at), "yyyy-MM-dd"),
        ];
        // push each tickcet's info into a row
        tableRows.push(rowData);
      });
    } else if (dataType === "User") {
      data.forEach((each) => {
        const rowData = [
          each.id,
          each.username,
          each.contact_number,
          each.email,
          each.roles[0],
          each.created_at.split("T")[0],
          // called date-fns to format the date on the ticket
          //format(new Date(ticket.updated_at), "yyyy-MM-dd"),
        ];
        // push each tickcet's info into a row
        tableRows.push(rowData);
      });
    }

    // startY is basically margin-top
    doc.autoTable({
      //styles: { fillColor: [0, 0, 0] },
      startY: 20,
      columns: columnData,
      body: tableRows,
      theme: "plain",
      styles: { halign: "center", lineColor: "DCE0E4", lineWidth: 0.2 },
      headStyles: {
        textColor: "black",
        fillColor: "#fafbfe",
      },
    });

    // ticket title. and margin-top + margin-left
    //doc.text(pdfTitle, 85, 10);
    // we define the name of our PDF file.
    doc.save(pdfTitle);
  }
};

export default generatePDF;
