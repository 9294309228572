import React from "react";
import { Row, Col, Form, Modal, Button } from "react-bootstrap";

const UpdateProductModal = (props) => {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title className="ms-auto" id="contained-modal-title-vcenter">
          Update Product
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-4">
        <Form>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>Asset id</Form.Label>
            <Form.Control type="number" placeholder="e.g:112200" />
          </Form.Group>
          <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
            <Form.Label>Asset Description</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              placeholder="Asset Description"
              style={{ height: "100px" }}
            />
          </Form.Group>
        </Form>
        <Row className="mt-3 d-flex justify-content-center">
          <Col xl={4} lg={6} className="mb-2 mb-lg-0">
            <Button className="submit-btn border-0">Update</Button>
          </Col>
          <Col xl={4} lg={6}>
            <Button className="cancel-btn border-0" onClick={props.onHide}>
              Cancel
            </Button>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default UpdateProductModal;
