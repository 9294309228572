import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col, Form, Modal, Button, Spinner } from "react-bootstrap";
import {
  updateUser,
  getUserRoleList,
  getUserList,
} from "../../Redux/actions/UserAction";
import { toast } from "react-toastify";

const UpdateUserlModal = (props) => {
  const dispatch = useDispatch();
  const [updateName, setUpdateName] = useState("");
  const [updateEmail, setUpdateEmail] = useState("");
  const [updatePassword, setUpdatePassword] = useState("");
  const [updateContactNo, setUpdateContactNo] = useState("");
  const [updateRole, setUpdateRole] = useState("");
  const [updateMsg, setUpdateMsg] = useState(false);
  const { userRoleList } = useSelector((state) => state.userRoleList);
  const { loading, message, error } = useSelector((state) => state.updateUser);
  useEffect(() => {
    if (props.user !== "") {
      setUpdateName(props.user.username);
      setUpdateEmail(props.user.email);
      setUpdateContactNo(props.user.contact_number);
      setUpdateRole(props.user.roles);
      setUpdatePassword("");
    }
  }, [props.user]);

  useEffect(() => {
    dispatch(getUserRoleList());
  }, [dispatch]);

  useEffect(() => {
    if (message !== undefined && updateMsg === true && loading === false) {
      props.onHide();
      updateUserSuccessAlert();
      dispatch(getUserList(10, 0));
      setUpdateMsg(false);
    }
    if (error !== undefined && updateMsg === true && loading === false) {
      console.log(error);
      if (error.email) {
        alert(error.email);
        setUpdateMsg(false);
      } else if (error.roles) {
        alert(error.roles);
        setUpdateMsg(false);
      } else if (error.contact_number) {
        alert(error.contact_number);
        console.log(error);
        setUpdateMsg(false);
      } else if (error.username) {
        alert(error.username);
        setUpdateMsg(false);
      } else if (error.password) {
        alert(error.password);
        setUpdateMsg(false);
      } else if (error.primary_email) {
        alert(error.primary_email);
        setUpdateMsg(false);
      } else {
        alert(error);
        setUpdateMsg(false);
      }
    }
  }, [message, updateMsg, error]);

  const updateUserHnadle = (e) => {
    if (updatePassword !== "" && updateContactNo !== null) {
      const body = {
        username: updateName.toLowerCase(),
        email: updateEmail,
        contact_number: updateContactNo,
        roles: updateRole,
        password: updatePassword,
      };
      //console.log("1");
      e.preventDefault();
      dispatch(updateUser(props.user.id, body));
      setUpdateMsg(true);
      //props.onHide();
    } else if (updateContactNo !== null && updateContactNo !== "") {
      const body = {
        username: updateName.toLowerCase(),
        email: updateEmail,
        contact_number: updateContactNo,
        roles: updateRole,
      };
      //console.log("2");
      e.preventDefault();
      dispatch(updateUser(props.user.id, body));
      setUpdateMsg(true);
    } else if (updatePassword !== "") {
      const body = {
        username: updateName.toLowerCase(),
        email: updateEmail,
        //contact_number: updateContactNo,
        roles: updateRole,
        password: updatePassword,
      };
      e.preventDefault();
      dispatch(updateUser(props.user.id, body));
      setUpdateMsg(true);
      //console.log("3");
    } else {
      const body = {
        username: updateName.toLowerCase(),
        email: updateEmail,
        //contact_number: updateContactNo,
        roles: updateRole,
      };
      //console.log("4");
      e.preventDefault();
      dispatch(updateUser(props.user.id, body));
      setUpdateMsg(true);
      //props.onHide();
    }
  };

  const updateUserSuccessAlert = () => {
    toast.info("User Updated Successfully", {
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  };
  //console.log(updateContactNo?.length);
  //console.log(updateRole[0]);
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title className="ms-auto" id="contained-modal-title-vcenter">
          Update User ({props.user ? props.user.id : " "})
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-4">
        <Form onSubmit={updateUserHnadle}>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>
              <b>User Name</b>
            </Form.Label>
            <Form.Control
              required
              value={updateName}
              onChange={(e) => setUpdateName(e.target.value)}
              type="text"
              placeholder="e.g:Demo"
              maxLength={updateRole[0] === "App-User" ? 5 : 90}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
            <Form.Label>
              <b>Contact No.</b>
            </Form.Label>
            <Form.Control
              type="text"
              value={updateContactNo}
              onChange={(e) => setUpdateContactNo(e.target.value)}
              placeholder="e.g:+880233422343"
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>
              <b>Email id</b>
            </Form.Label>
            <Form.Control
              type="email"
              value={updateEmail}
              onChange={(e) => setUpdateEmail(e.target.value)}
              placeholder="e.g:demo@email.com"
            />
          </Form.Group>
          <Form.Group className=" form-group mb-3" controlId="formBasicEmail">
            <Form.Label>
              <b>User Role</b>
            </Form.Label>
            <Form.Select
              required
              aria-label="Default select example"
              onChange={(e) => setUpdateRole([e.target.value])}
            >
              <option value=".." selected disabled>
                Select Role
              </option>
              {userRoleList !== {} && userRoleList?.data?.length > 0 ? (
                userRoleList?.data?.map((role, index) => (
                  <option key={index} value={role.name}>
                    {role.name}
                  </option>
                ))
              ) : (
                <></>
              )}
            </Form.Select>
          </Form.Group>
          <Form.Group className="form-group mb-3" controlId="formBasicEmail">
            <Form.Label>
              <b>Update Password</b>
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="****"
              value={updatePassword}
              onChange={(e) => setUpdatePassword(e.target.value)}
            />
          </Form.Group>
          {loading === true ? (
            <Row className="mt-2 d-flex justify-content-center">
              <Spinner animation="border" variant="primary" />
            </Row>
          ) : (
            <></>
          )}
          <Row className="mt-3 d-flex justify-content-center">
            <Col xl={4} lg={6} className="mb-2 mb-lg-0">
              <Button className="cancel-btn border-0" onClick={props.onHide}>
                Cancel
              </Button>
            </Col>
            <Col xl={4} lg={6} className="mb-2 mb-lg-0">
              <Button type="submit" className="submit-btn border-0">
                Update
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default UpdateUserlModal;
