import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { login } from "../../Redux/actions/UserAction";
import pubLogo from "../../Assets/logo/Logo-03.png";
import { Row, Col, Button, Spinner } from "react-bootstrap";
import logo from "../../Assets/icons/pub.png";
import loginSideImage from "../../Assets/pubLogin.png";
import "./Login.css";

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loading, userInfo, error } = useSelector((state) => state.userLogin);

  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [errorAlert, setErrorAlert] = useState(false);
  const location = useLocation();
  //const { from } = location.state || { from: { pathname: "/" } };

  const onSignInSubmit = (e) => {
    e.preventDefault();
    dispatch(login(name.toLowerCase(), password));
  };

  useEffect(() => {
    if (error) {
      navigate("/login");
      localStorage.setItem("url", location.pathname);
      //window.location.reload();
      setErrorAlert(true);
      console.log(error);
    } else if (userInfo) {
      navigate("/", { replace: true });
    } else {
      // history.push("/home");
      // history.replace(from);
      // dispatch(getUserDetails());
      setErrorAlert(false);
    }
  }, [error, userInfo]);

  //console.log(loading);
  //console.log(name.toLowerCase());
  return (
    <div style={{ height: "100vh", overflowX: "hidden" }}>
      <Row>
        <Col className="d-none d-lg-block" lg={5} md={0}>
          <img
            src={loginSideImage}
            alt="login"
            style={{ height: "100vh", width: "42vw" }}
          />
        </Col>
        <Col
          lg={7}
          md={12}
          className="login-container d-flex flex-column justify-content-center align-items-center"
        >
          {errorAlert ? (
            <div className="alert alert-danger fade show" role="alert">
              <p className="text-center">
                <i className="bi bi-exclamation-triangle-fill"></i> Request
                Failed!! Give Correct Informations !!
              </p>
            </div>
          ) : (
            <div></div>
          )}
          <img src={pubLogo} alt="pubLogo" height={45} />
          <h4
            className="pt-4 text-center"
            style={{
              fontWeight: "700",
              fontSize: "25px",
              //lineHeight: "43.88px",
            }}
          >
            Asset Verification System Admin Login
          </h4>
          <form className="mt-4 form_container" onSubmit={onSignInSubmit}>
            <div className="pt-3 pb-3 text-center">
              <input
                type="text"
                placeholder="User Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
              />
            </div>
            <div className="pb-3 text-center">
              <input
                type="password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </div>
            {loading === true ? (
              <>
                <Row className="mb-2 d-flex justify-content-center align-items-center">
                  <Spinner animation="border" variant="primary" />
                </Row>
                <Button variant="secondary" disabled>
                  Login
                </Button>
              </>
            ) : (
              <button
                className="login-button mb-2"
                type="submit"
                //onClick={() => navigate("/")}
              >
                LOGIN
              </button>
            )}
            {/* <button
              className="mb-2"
              type="submit"
              //onClick={() => navigate("/")}
            >
              LOGIN
            </button> */}
          </form>
          <div style={{ position: "absolute", bottom: "20px" }}>
            <p
              style={{ fontWeight: "400", fontSize: "14px", color: "#DCE0E4" }}
            >
              Powered By | SmartMatrix PTE LTD.
            </p>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Login;
