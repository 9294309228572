import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getEmailList, deleteEmailId } from "../../Redux/actions/EmailAction";
import { getUserList, deleteUser } from "../../Redux/actions/UserAction";
import {
  getProductList,
  deleteProductAction,
  deleteScannedProductAction,
  getScannedProductList,
} from "../../Redux/actions/InventroyAction";
import { toast } from "react-toastify";
import { Row, Modal, Button } from "react-bootstrap";

const DeleteModal = (props) => {
  const dispatch = useDispatch();
  const [alertmsg, setAlertMsg] = useState(false);
  const [userAlertmsg, setUserAlertMsg] = useState(false);
  const [assetAlertmsg, setAssetAlertMsg] = useState(false);
  const [scanAlertmsg, setScanAlertMsg] = useState(false);
  const { loading, message, error } = useSelector((state) => state.deleteEmail);
  const deleteUserResponse = useSelector((state) => state.deleteUser);
  const deleteAssetResponse = useSelector((state) => state.deleteProduct);
  const deleteScanAssetResponse = useSelector(
    (state) => state.deleteScanProduct
  );
  useEffect(() => {
    if (message !== undefined && alertmsg === true) {
      deleteSuccessAlert();
      dispatch(getEmailList());
      setAlertMsg(false);
      props.onAction();
    }
    if (error !== undefined && alertmsg === true) {
      setAlertMsg(false);
      alert("Something Went Wrong.Try Again Later");
    }
  }, [message, alertmsg]);

  useEffect(() => {
    if (deleteUserResponse.message !== undefined && userAlertmsg === true) {
      //console.log("inside", deleteUserResponse.message);
      deleteUserSuccessAlert();
      dispatch(getUserList(10, props.offset));
      setUserAlertMsg(false);
      props.onAction();
    }
    if (deleteUserResponse.error !== undefined && userAlertmsg === true) {
      setUserAlertMsg(false);
      alert("Something Went Wrong.Try Again Later");
    }
  }, [deleteUserResponse]);

  useEffect(() => {
    if (deleteAssetResponse.message !== undefined && assetAlertmsg === true) {
      deleteAssetSuccessAlert();
      dispatch(getProductList(10, props.offset));
      setAssetAlertMsg(false);
      props.onAction();
    }
    if (deleteAssetResponse.error !== undefined && assetAlertmsg === true) {
      setAssetAlertMsg(false);
      alert("Something Went Wrong.Try Again Later");
    }
  }, [deleteAssetResponse]);

  useEffect(() => {
    if (
      deleteScanAssetResponse.message !== undefined &&
      scanAlertmsg === true
    ) {
      deleteScanAssetSuccessAlert();
      dispatch(getScannedProductList(10, props.offset));
      setScanAlertMsg(false);
      props.onAction();
    }
    if (deleteScanAssetResponse.error !== undefined && scanAlertmsg === true) {
      setScanAlertMsg(false);
      alert("Something Went Wrong.Try Again Later");
    }
  }, [deleteScanAssetResponse]);

  const handleDeleteEmail = () => {
    if (props.useremail) {
      dispatch(deleteEmailId(props.useremail));
      setAlertMsg(true);
      props.onHide();
      //console.log(message);
    } else if (props.user) {
      dispatch(deleteUser(props.user.id));
      setUserAlertMsg(true);
      props.onHide();
      //console.log(deleteUserResponse);
    } else if (props.asset) {
      dispatch(deleteProductAction(props.asset.id));
      setAssetAlertMsg(true);
      props.onHide();
    } else if (props.scanasset) {
      dispatch(deleteScannedProductAction(props.scanasset.id));
      setScanAlertMsg(true);
      props.onHide();
    } else {
      props.onHide();
    }
  };
  //console.log("email", error);
  //console.log(deleteAssetResponse);
  const deleteSuccessAlert = () => {
    toast.info(`Email Delete Successfully`, {
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  };

  const deleteUserSuccessAlert = () => {
    toast.info("User Deleted Successfully", {
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  };
  const deleteAssetSuccessAlert = () => {
    toast.info("Asset Deleted Successfully", {
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  };
  const deleteScanAssetSuccessAlert = () => {
    toast.info("Scanned Asset Deleted Successfully", {
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  };
  //console.log(message);
  //console.log(error);
  return (
    <>
      <Modal {...props}>
        <Modal.Body className="p-4">
          <Row className="d-flex justify-content-center">
            {props.useremail
              ? `"${props.useremail}"`
              : props.user
              ? `"${props.user.username}"`
              : props.asset
              ? `"${props.asset.name}"`
              : props.scanasset
              ? `"${props.scanasset.name}"`
              : "Data"}{" "}
            will be deleted Permanently
          </Row>
          <Row className="d-flex justify-content-center">Are you sure??</Row>
          <Row className="mt-3">
            <div className="d-flex justify-content-center">
              <Button variant="danger" onClick={handleDeleteEmail}>
                Delete
              </Button>
              <Button
                className="ms-3"
                variant="secondary"
                onClick={props.onHide}
              >
                Cancel
              </Button>
            </div>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default DeleteModal;
