import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  getUserList,
  getAllUserList,
  logout,
} from "../../Redux/actions/UserAction";
import Sidebar from "../Sidebar/Sidebar";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import UpdateUserlModal from "../PopupModal/UpdateUserModal";
import DeleteModal from "../PopupModal/DeleteModal";
import {
  Container,
  Row,
  Col,
  Table,
  Form,
  OverlayTrigger,
  Tooltip,
  Spinner,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronRight,
  faPenAlt,
  faTrashCan,
  faAdd,
} from "@fortawesome/free-solid-svg-icons";
import { ToastContainer, toast } from "react-toastify";
import pdfIcon from "../../Assets/icons/pdf.png";
import excelIcon from "../../Assets/icons/excel.png";
import filter from "../../Assets/icons/search.png";
import txtIcon from "../../Assets/icons/TXT.png";
import "../InventoryManagement/Inventory.css";
import ReactPaginate from "react-paginate";
import generatePDF from "../../services/pdfGenerator";
import { CSVLink } from "react-csv";

const UserList = () => {
  const dispatch = useDispatch();
  const [modalShow, setModalShow] = useState(false);
  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const [selectedUser, setSelectedUser] = useState("");
  const [searchVal, setSearchVal] = useState("");
  const [searchResult, setSearchResult] = useState("");
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [totalUsers, setTotalUsers] = useState(0);
  const [sideMenu, setSideMenu] = useState(true);
  const navigate = useNavigate();
  const { userList, loading, error } = useSelector(
    (state) => state.userListDetails
  );
  const { getloading, allUserList, allUserListError } = useSelector(
    (state) => state.getAllUserList
  );
  useEffect(() => {
    dispatch(getUserList(limit, offset));
  }, [dispatch, offset]);

  useEffect(() => {
    if (userList?.data?.count) {
      setTotalUsers(userList?.data?.count);
    }
  }, [userList]);

  useEffect(() => {
    if (totalUsers === userList?.data?.count) {
      dispatch(getAllUserList(totalUsers));
      //console.log("inside");
    }
  }, [totalUsers]);

  useEffect(() => {
    if (searchVal.length !== 0 && userList !== {}) {
      const results = allUserList?.data?.results.filter(
        (d) =>
          d.id?.toString().toLowerCase().includes(searchVal.toLowerCase()) ||
          d.username?.toLowerCase().includes(searchVal.toLowerCase()) ||
          d.contact_number?.toLowerCase().includes(searchVal.toLowerCase()) ||
          d.email?.toLowerCase().includes(searchVal.toLowerCase()) ||
          d.roles[0]?.toLowerCase().includes(searchVal.toLowerCase())
      );
      setSearchResult(results);
    } else {
      //dispatch(getProductList(limit, 0));
      setSearchResult([]);
    }
  }, [searchVal]);

  useEffect(() => {
    if (error?.code === "token_not_valid") {
      errorAlert();
      dispatch(logout());
      navigate(0);
      //console.log("inside");
    }
  }, [error]);
  //console.log(error);
  const errorAlert = () => {
    toast.error("Your Login Session Expired", {
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  };
  const handleClickDelete = (user) => {
    setSelectedUser(user);
    setDeleteModalShow(true);
  };
  const hanleClickUpdate = (user) => {
    setSelectedUser(user);
    setModalShow(true);
  };
  const pageCount = Math.ceil(totalUsers / limit);

  const handlePageClick = (event) => {
    // pub newOffset = (event.selecter * limit) % count
    // dispatch(api(limit , offset))
    const newOffset = (event.selected * limit) % totalUsers;
    setOffset(newOffset);
  };

  const downloadTextFile = (array) => {
    if (array === undefined && getloading === false) {
      alert("No Data Found");
    } else if (array === undefined && getloading === true) {
      alert("Data is not ready for download . Try Again after a minute");
    } else {
      const element = document.createElement("a");
      const file = new Blob(
        [
          "\t \t \t PUB USER LIST \n \n" +
            array
              .map(
                (arr) =>
                  arr.id +
                  "\t" +
                  arr.username +
                  "\t" +
                  arr.contact_number +
                  "\t" +
                  arr.email +
                  "\t" +
                  "\t" +
                  arr.roles[0] +
                  "\t"
              )
              .join("\n"),
        ],
        {
          type: "text/plain",
        }
      );
      element.href = URL.createObjectURL(file);
      element.download = "pub_user_list.txt";
      document.body.appendChild(element); // Required for this to work in FireFox
      element.click();
    }
  };

  const headers = [
    { label: "User Id", key: "id" },
    { label: "User Name", key: "username" },
    { label: "Contact No.", key: "contact_number" },
    { label: "Email", key: "email" },
    { label: "Role", key: "roles[0]" },
  ];
  const csvReport = {
    data: allUserList?.data?.results,
    headers: headers,
    filename: "PUB_USER_LIST.csv",
  };
  //console.log(userList);
  return (
    <div>
      <UpdateUserlModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        backdrop="static"
        keyboard={false}
        user={selectedUser}
      />
      <DeleteModal
        show={deleteModalShow}
        onHide={() => setDeleteModalShow(false)}
        user={selectedUser}
        onAction={() => setSearchVal("")}
        offset={offset}
      />
      <Sidebar setSideMenu={setSideMenu} sideMenu={sideMenu}></Sidebar>
      <div className="mainContent">
        <ToastContainer />
        <Header setSideMenu={setSideMenu} sideMenu={sideMenu}></Header>
        <Container fluid>
          <Row className="ms-2 me-2">
            <Col lg={6}>
              <h4 className="product-heading">User List</h4>
              <p className="product-paragraph">
                {" "}
                User Management{" "}
                <FontAwesomeIcon icon={faChevronRight} size="xs" /> User List
              </p>
            </Col>
            <Col lg={6} className="d-flex justify-content-lg-end">
              <button
                className="pe-3 ps-3 border-0 add-product-button"
                onClick={() => navigate("/addUser")}
              >
                <FontAwesomeIcon
                  icon={faAdd}
                  style={{ paddingRight: "0.4rem" }}
                />
                Add User
              </button>
            </Col>
          </Row>
          <div
            className="mb-4 p-3 card product-card container-view"
            style={{ maxHeight: "700px" }}
          >
            <Row className="mb-4">
              <Col lg={6} md={8}>
                <img src={filter} alt="filter" height={45} />
                <input
                  className="search-bar"
                  type="search"
                  placeholder="Search"
                  aria-label="Search"
                  value={searchVal}
                  onChange={(e) => setSearchVal(e.target.value)}
                />
              </Col>
              <Col lg={6} md={4} className="d-flex justify-content-end">
                <OverlayTrigger
                  key="export1"
                  placement="top"
                  overlay={<Tooltip id="tooltip-top">Export as a .txt</Tooltip>}
                >
                  <img
                    className="pe-3"
                    src={txtIcon}
                    alt="txt"
                    height={45}
                    onClick={() => downloadTextFile(allUserList?.data?.results)}
                    style={{ cursor: "pointer" }}
                  />
                </OverlayTrigger>
                <OverlayTrigger
                  key="export2"
                  placement="top"
                  overlay={<Tooltip id="tooltip-top">Export as a pdf</Tooltip>}
                >
                  <img
                    className="pe-3"
                    src={pdfIcon}
                    alt="pdf"
                    height={45}
                    onClick={() =>
                      generatePDF(
                        allUserList?.data?.results,
                        "User",
                        [
                          "User ID",
                          "User Name",
                          "Contact No.",
                          "Email",
                          "User Role",
                          "Created Date",
                        ],
                        "PUB User List",
                        totalUsers
                      )
                    }
                    style={{ cursor: "pointer" }}
                  />
                </OverlayTrigger>
                {allUserList !== {} &&
                allUserList?.data?.results !== undefined &&
                getloading === false ? (
                  <CSVLink
                    {...csvReport}
                    //className="csv-report-btn"
                    target="_blank"
                  >
                    <img src={excelIcon} alt="pdf" height={45} />
                  </CSVLink>
                ) : (
                  <img
                    src={excelIcon}
                    alt="pdf"
                    height={45}
                    onClick={() =>
                      getloading === true
                        ? alert(
                            "Data is not ready for download . Try Again after a minute"
                          )
                        : alert("No Data Found")
                    }
                    style={{ cursor: "pointer" }}
                  />
                )}
              </Col>
            </Row>
            <Table responsive hover>
              <thead className="text-center table-header">
                <tr>
                  <th>User Id</th>
                  <th>User Name</th>
                  <th>Contact No.</th>
                  <th>Email</th>
                  <th>Role</th>
                  <th>Created Date</th>
                  <th>Actions</th>
                </tr>
              </thead>
              {searchVal === "" &&
              userList !== {} &&
              userList?.data !== undefined ? (
                <tbody className="text-center">
                  {userList?.data?.results.map((user, index) => (
                    <tr key={index}>
                      <td>{user.id}</td>
                      <td>{user.username}</td>
                      <td>
                        {user.contact_number ? user.contact_number : "N/A"}
                      </td>
                      <td>{user.email}</td>
                      <td>{user.roles[0]}</td>
                      <td>{user.created_at.split("T")[0]}</td>
                      <td style={{ cursor: "pointer" }}>
                        <OverlayTrigger
                          key="update"
                          placement="top"
                          overlay={<Tooltip id="tooltip-top">Update</Tooltip>}
                        >
                          <FontAwesomeIcon
                            //className="d-none"
                            icon={faPenAlt}
                            color="#637381"
                            onClick={() => hanleClickUpdate(user)}
                          />
                        </OverlayTrigger>
                        <OverlayTrigger
                          key="delete"
                          placement="top"
                          overlay={<Tooltip id="tooltip-top">Delete</Tooltip>}
                        >
                          <FontAwesomeIcon
                            icon={faTrashCan}
                            color="#E40000"
                            style={{ paddingLeft: "0.8rem" }}
                            onClick={() => handleClickDelete(user)}
                          />
                        </OverlayTrigger>
                      </td>
                    </tr>
                  ))}
                </tbody>
              ) : loading === true ? (
                <tbody className="text-center">
                  <tr>
                    <td colSpan="12">
                      <Spinner animation="border" variant="primary" />
                    </td>
                  </tr>
                </tbody>
              ) : searchVal !== "" && searchResult.length !== 0 ? (
                <tbody className="text-center">
                  {searchResult?.map((user, index) => (
                    <tr key={index}>
                      <td>{user.id}</td>
                      <td>{user.username}</td>
                      <td>
                        {user.contact_number ? user.contact_number : "N/A"}
                      </td>
                      <td>{user.email}</td>
                      <td>{user.roles[0]}</td>
                      <td>{user.created_at.split("T")[0]}</td>
                      <td style={{ cursor: "pointer" }}>
                        <OverlayTrigger
                          key="update"
                          placement="top"
                          overlay={<Tooltip id="tooltip-top">Update</Tooltip>}
                        >
                          <FontAwesomeIcon
                            //className="d-none"
                            icon={faPenAlt}
                            color="#637381"
                            onClick={() => hanleClickUpdate(user)}
                          />
                        </OverlayTrigger>
                        <OverlayTrigger
                          key="delete"
                          placement="top"
                          overlay={<Tooltip id="tooltip-top">Delete</Tooltip>}
                        >
                          <FontAwesomeIcon
                            icon={faTrashCan}
                            color="#E40000"
                            style={{ paddingLeft: "0.8rem" }}
                            onClick={() => handleClickDelete(user)}
                          />
                        </OverlayTrigger>
                      </td>
                    </tr>
                  ))}
                </tbody>
              ) : (
                <tbody className="text-center">
                  <tr>
                    <td colSpan="12">No Results Found</td>
                  </tr>
                </tbody>
              )}
            </Table>
            {pageCount > 1 ? (
              <ReactPaginate
                breakLabel="..."
                nextLabel=""
                onPageChange={handlePageClick}
                pageRangeDisplayed={3}
                marginPagesDisplayed={1}
                pageCount={pageCount}
                previousLabel=""
                renderOnZeroPageCount={null}
                containerClassName="pagination"
                pageLinkClassName="page-num"
                previousClassName="hide"
                nextClassName="hide"
                activeLinkClassName="active"
              />
            ) : (
              <></>
            )}
          </div>
        </Container>
      </div>
      <Footer></Footer>
    </div>
  );
};

export default UserList;
