import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  getUserRoleList,
  createUserAction,
} from "../../Redux/actions/UserAction";
import { useNavigate } from "react-router-dom";
import Sidebar from "../Sidebar/Sidebar";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import CreateRoleModal from "../PopupModal/CreateRoleModal";
import { Button, Col, Container, Form, Row, Spinner } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight, faPlus } from "@fortawesome/free-solid-svg-icons";
import { toast, ToastContainer } from "react-toastify";
import "../InventoryManagement/Inventory.css";

const AddUser = () => {
  const dispatch = useDispatch();
  const [modalShow, setModalShow] = useState(false);
  const [alertMessage, setAlertMessage] = useState(false);
  const [roleList, setRoleList] = useState();
  const [userName, setUserName] = useState("");
  const [emailId, setEmailId] = useState("");
  const [contactNo, setContactNo] = useState("");
  const [userPassword, setUserPassword] = useState("");
  const [userRole, setUserRole] = useState([]);
  const [sideMenu, setSideMenu] = useState(true);
  const { userRoleList } = useSelector((state) => state.userRoleList);
  const { loading, message, error } = useSelector((state) => state.createUser);
  useEffect(() => {
    dispatch(getUserRoleList());
    //fetchUserList();
  }, []);
  useEffect(() => {
    if (alertMessage && message === undefined && error !== undefined) {
      //console.log(error);
      if (error.email) {
        alert(error.email);
        setAlertMessage(false);
      } else if (error.roles) {
        alert(`User Role: ${error.roles}`);
        setAlertMessage(false);
      } else if (error.contact_number) {
        console.log(error.contact_number);
        alert(`Contact No: ${error.contact_number}`);
        setAlertMessage(false);
      } else if (error.username) {
        alert(error.username);
        setAlertMessage(false);
      } else if (error.password) {
        alert(error.password);
        setAlertMessage(false);
      } else if (error.primary_email) {
        alert(error.primary_email);
        setAlertMessage(false);
      } else {
        alert(error);
        setAlertMessage(false);
      }
    }
    if (alertMessage && message !== undefined) {
      setUserName("");
      setEmailId("");
      setContactNo("");
      setUserPassword("");
      setUserRole([]);
      successAlert();
      setAlertMessage(false);
    }
  }, [message, error]);
  //console.log(userRole[0]);
  const successAlert = () => {
    toast.info("New User-Id Created Successfully", {
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  };
  const handleCreateUser = (e) => {
    e.preventDefault();
    if (userRole !== []) {
      dispatch(
        createUserAction(
          userName.toLowerCase(),
          emailId,
          contactNo,
          userPassword,
          userRole
        )
      );
      setAlertMessage(true);
    } else {
      alert("Select User Role");
    }
  };
  const handleCancelButton = (e) => {
    e.preventDefault();
    setUserName("");
    setEmailId("");
    setContactNo("");
    setUserPassword("");
    setUserRole([]);
  };
  return (
    <div>
      <CreateRoleModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        backdrop="static"
        keyboard={false}
      />
      <Sidebar setSideMenu={setSideMenu} sideMenu={sideMenu}></Sidebar>
      <div className="mainContent">
        <ToastContainer />
        <Header setSideMenu={setSideMenu} sideMenu={sideMenu}></Header>
        <Container fluid>
          <h4 className="product-heading container-view">Add User</h4>
          <p className="product-paragraph container-view">
            User Management
            <FontAwesomeIcon
              icon={faChevronRight}
              size="xs"
              style={{ paddingLeft: "5px" }}
            />{" "}
            Add User
          </p>
          <div className="mb-4 card product-card container-view">
            <Form className="p-4" onSubmit={handleCreateUser}>
              <Row>
                <Col lg={6}>
                  <Form.Group
                    className="form-group mb-3"
                    controlId="formBasicEmail"
                  >
                    <Form.Label>
                      User Name{" "}
                      <span style={{ color: "#c70a5f", fontSize: "19px" }}>
                        *
                      </span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="e.g: Jhon"
                      value={userName}
                      onChange={(e) => setUserName(e.target.value)}
                      maxLength={userRole[0] === "App-User" ? 5 : 90}
                      required
                    />
                  </Form.Group>
                </Col>
                <Col lg={6}>
                  <Form.Group
                    className="form-group mb-3"
                    controlId="formBasicEmail"
                  >
                    <Form.Label>Contact Number </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="e.g:+65 XXXX XXXX"
                      value={contactNo}
                      onChange={(e) => setContactNo(e.target.value)}
                      //required
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col lg={6}>
                  <Form.Group
                    className="form-group mb-3"
                    controlId="formBasicEmail"
                  >
                    <Form.Label>
                      Password{" "}
                      <span style={{ color: "#c70a5f", fontSize: "19px" }}>
                        *
                      </span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="e.g:1234..."
                      value={userPassword}
                      onChange={(e) => setUserPassword(e.target.value)}
                      required
                    />
                  </Form.Group>
                </Col>
                <Col lg={6}>
                  <Form.Group
                    className="form-group mb-3"
                    controlId="formBasicEmail"
                  >
                    <Form.Label>
                      Email Address{" "}
                      <span style={{ color: "#c70a5f", fontSize: "19px" }}>
                        *
                      </span>
                    </Form.Label>
                    <Form.Control
                      type="email"
                      placeholder="e.g:demo@email.com"
                      value={emailId}
                      onChange={(e) => setEmailId(e.target.value)}
                      required
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col lg={6}>
                  <Form.Group
                    className=" form-group mb-3"
                    controlId="formBasicEmail"
                  >
                    <Form.Label>
                      User Role{" "}
                      <span style={{ color: "#c70a5f", fontSize: "19px" }}>
                        *
                      </span>
                    </Form.Label>
                    <Form.Select
                      required
                      aria-label="Default select example"
                      onChange={(e) =>
                        setUserRole([e.target.value.replace(/'/g, '"')])
                      }
                    >
                      <option value=".." selected disabled>
                        Select Role
                      </option>
                      {userRoleList !== {} && userRoleList?.data?.length > 0 ? (
                        userRoleList?.data?.map((role, index) => (
                          <option key={index} value={role.name}>
                            {role.name}
                          </option>
                        ))
                      ) : (
                        <></>
                      )}
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col lg={6} className="d-none mt-1">
                  <div className="mt-4">
                    <Button
                      className="create-btn border-0"
                      onClick={() => setModalShow(true)}
                    >
                      <FontAwesomeIcon className="pe-2" icon={faPlus} />
                      Create Role
                    </Button>
                  </div>
                </Col>
              </Row>
              {loading === true ? (
                <Row className="mt-2 d-flex justify-content-center">
                  <Spinner animation="border" variant="primary" />
                </Row>
              ) : (
                <></>
              )}
              <Row className="mt-5">
                <Col xl={2} lg={6} className="mb-2 mb-lg-0">
                  <Button type="submit" className="submit-btn border-0">
                    Submit
                  </Button>
                </Col>
                <Col xl={2} lg={6}>
                  <Button
                    className="cancel-btn border-0"
                    onClick={handleCancelButton}
                  >
                    Cancel
                  </Button>
                </Col>
              </Row>
            </Form>
          </div>
        </Container>
      </div>
      <Footer></Footer>
    </div>
  );
};

export default AddUser;
