import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { logout } from "../../Redux/actions/UserAction";
import { getDashboardCalculation } from "../../Redux/actions/DashboardAction";
import { getScannedProductList } from "../../Redux/actions/InventroyAction";
import Header from "../Header/Header";
import Sidebar from "../Sidebar/Sidebar";
import {
  Container,
  Row,
  Col,
  Table,
  Button,
  ProgressBar,
} from "react-bootstrap";
import Footer from "../Footer/Footer";
import totalProductsIcon from "../../Assets/icons/totalProducts.png";
import totalUserIcon from "../../Assets/icons/totalUser.png";
import totoalEmailIcon from "../../Assets/icons/totalEmail.png";
import animatedFile from "../../Assets/icons/business-investor-gaining-profit-from-investment.gif";
import "./Dashboard.css";
import "../InventoryManagement/Inventory.css";
import { toast, ToastContainer } from "react-toastify";
import { Pie } from "react-chartjs-2";
import { Chart, registerables } from "chart.js";
Chart.register(...registerables);

const Dashboard = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [sideMenu, setSideMenu] = useState(true);
  const { loading, calculation, error } = useSelector(
    (state) => state.dashboardCalculation
  );
  const { scannedProductList, getListError } = useSelector(
    (state) => state.getScannedProducts
  );
  useEffect(() => {
    dispatch(getDashboardCalculation());
    dispatch(getScannedProductList(5, 0));
  }, [dispatch]);
  useEffect(() => {
    if (getListError?.code === "token_not_valid") {
      errorAlert();
      dispatch(logout());
      navigate(0);
      //console.log("inside");
    }
  }, [getListError]);

  const errorAlert = () => {
    toast.error("Your Login Session Expired", {
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  };
  const progressBarColor = [
    "first-progress-bar",
    "second-progress-bar",
    "third-progress-bar",
    "fourth-progress-bar",
    "fifth-progress-bar",
  ];
  const progressBarVariant = [
    "first-bar",
    "second-bar",
    "third-bar",
    "fourth-bar",
    "fifth-bar",
  ];
  //console.log(scannedProductList?.data.count);
  return (
    <div>
      <Sidebar setSideMenu={setSideMenu} sideMenu={sideMenu}></Sidebar>
      <div className="mainContent">
        <ToastContainer></ToastContainer>
        <Header setSideMenu={setSideMenu} sideMenu={sideMenu}></Header>
        <Container fluid className="mb-4">
          <Row className="container-view">
            {/**
             * <Col xl={4}>
              <div className="dashborad-card">
                <div className="d-flex row px-3 py-3">
                  <p style={{ fontWeight: "600", fontSize: "20px" }}>
                    Welcome to PUB Admin
                  </p>
                  <Row>
                    <Col className="">
                      <p className="dashboard-card-text">
                        Congrats on your new admin dashboard!
                      </p>
                      <Button className="mt-1 border-0 dashboard-button">
                        Well Done!!
                      </Button>
                    </Col>
                    <Col className="d-flex justify-content-start">
                      <img
                        src={animatedFile}
                        alt="Computer man"
                        style={{ maxHeight: "123px", maxWidth: "196px" }}
                      />
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
             */}
            <Col xl={12}>
              <div className="dashborad-card">
                <div className="p-3">
                  <Row className="py-4">
                    <Col
                      xl={4}
                      className="py-3 px-5 d-flex justify-content-start"
                    >
                      <img
                        className="dashboard-card-image"
                        src={totalProductsIcon}
                        alt="totalProductsIcon"
                      />
                      <div className="dashboard-card-para">
                        <span className="dashboard-card-count">
                          {calculation?.data
                            ? calculation?.data[1]?.totalproduct
                            : 0}
                        </span>
                        <p className="card-text">Total Assets</p>
                      </div>
                    </Col>
                    <Col
                      xl={4}
                      className="py-3 px-5 d-flex justify-content-start"
                    >
                      <img
                        className="dashboard-card-image"
                        src={totalUserIcon}
                        alt="totalUserIcon"
                      />
                      <div className="dashboard-card-para">
                        <span className="dashboard-card-count">
                          {calculation?.data
                            ? calculation?.data[0]?.totaluser
                            : 0}
                        </span>
                        <p className="card-text">Total Users</p>
                      </div>
                    </Col>
                    <Col
                      xl={4}
                      className="py-3 px-5 d-flex justify-content-start"
                    >
                      <img
                        className="dashboard-card-image"
                        src={totoalEmailIcon}
                        alt="totoalEmailIcon"
                      />
                      <div className="dashboard-card-para">
                        <span className="dashboard-card-count">
                          {calculation?.data ? calculation?.data[2]?.email : 0}
                        </span>
                        <p className="card-text">Total Emails</p>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
          </Row>
          <Row className="container-view">
            <Col xl={4}>
              <div className="product-card">
                <p className="px-4 py-3 dashboard-card-header">
                  Scanned Asset Analysis
                </p>
                <div
                  className="mb-2 d-flex justify-content-center"
                  style={{ height: "260px" }}
                >
                  {/* {calculation?.data && calculation?.data[3].length > 0 ? (
                    calculation?.data[3]?.map((user, index) => (
                      <div className="mt-2" key={index}>
                        <label>{user.username}</label>
                        <ProgressBar
                          className={
                            progressBarColor[index]
                              ? progressBarColor[index]
                              : progressBarColor[0]
                          }
                          variant={
                            progressBarVariant[index]
                              ? progressBarVariant[index]
                              : progressBarVariant[0]
                          }
                          now={`${
                            user.scan_data !== 0
                              ? (user.scan_data /
                                  scannedProductList?.data?.count) *
                                100
                              : 0
                          }`}
                          //now={60}
                          label={`${
                            user.scan_data !== 0
                              ? (
                                  (user.scan_data /
                                    scannedProductList?.data?.count) *
                                  100
                                ).toFixed(1)
                              : 0
                          }%`}
                        />
                      </div>
                    ))
                  ) : (
                    <>
                      <p className="text-center">No Results Found</p>
                    </>
                  )} */}
                  <Pie
                    data={{
                      labels: [
                        `Total Assets: ${
                          calculation?.data
                            ? calculation?.data[1]?.totalproduct
                            : 0
                        }`,
                        `Total Scanned: ${
                          scannedProductList?.data !== undefined
                            ? scannedProductList?.data.count
                            : 0
                        }`,
                      ],
                      datasets: [
                        {
                          label: "Analysis",
                          data: [
                            calculation?.data
                              ? calculation?.data[1]?.totalproduct
                              : 0,
                            scannedProductList?.data !== undefined
                              ? scannedProductList?.data.count
                              : 0,
                          ],
                          backgroundColor: [
                            "#1c449c",
                            "rgba(0, 117, 191, 0.1)",
                          ],

                          hoverOffset: 4,
                        },
                      ],
                    }}
                    style={{ height: "250px", width: "250px" }}
                  />
                </div>
              </div>
            </Col>
            <Col xl={8}>
              <div className="product-card">
                <p className="ps-4 pt-4 dashboard-card-header">
                  Recently Scanned Assets (Last 5)
                </p>
                <div className="ps-4 pe-4">
                  <Table responsive hover>
                    <thead className="text-center table-header">
                      <tr>
                        <th>Asset ID</th>
                        <th>Sub Number</th>
                        <th>Inventory Number</th>
                        <th>Cost Center</th>
                        <th>Department</th>
                        <th>Scanned Date</th>
                        <th>Email Status</th>
                      </tr>
                    </thead>
                    <tbody className="text-center">
                      {scannedProductList !== {} &&
                      scannedProductList?.data !== undefined &&
                      scannedProductList?.data.results.length > 0 ? (
                        scannedProductList?.data?.results.map((list) => (
                          <tr key={list.id}>
                            <td>{list.asset}</td>
                            <td>{list.sub_number}</td>
                            <td>{list.inventory_number}</td>
                            <td>{list.cost_center}</td>
                            <td>{list.department}</td>
                            <td>{list.created_at.split("T")[0]}</td>
                            <td>
                              {list.email_sent_status === "send" ? (
                                <button
                                  className="border-0"
                                  style={{
                                    background: "#1C449C",
                                    color: "white",
                                    fontSize: "14px",
                                    borderRadius: "5px",
                                    width: "80px",
                                    cursor: "not-allowed",
                                  }}
                                >
                                  Sent
                                </button>
                              ) : (
                                <button
                                  className="border-0"
                                  style={{
                                    background: "rgba(0, 117, 191, 0.1)",
                                    color: "#1B2850",
                                    fontSize: "14px",
                                    borderRadius: "5px",
                                    width: "80px",
                                    cursor: "not-allowed",
                                    //opacity: "0",
                                  }}
                                >
                                  Pending
                                </button>
                              )}
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="12">No Result Found</td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                </div>
              </div>
            </Col>
          </Row>
          <Row className="d-none container-view">
            <Col xl={8}>
              <div className="product-card" style={{ height: "325px" }}>
                <p className="p-4 dashboard-card-header">Assets Analysis</p>
                <div className="p-5 text-center">Graph</div>
              </div>
            </Col>
            <Col xl={4}>
              <div className="product-card" style={{ height: "325px" }}>
                <p className="p-4 dashboard-card-header">Asset Type Analysis</p>
                <div className="p-5 text-center">Graph</div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <Footer></Footer>
    </div>
  );
};

export default Dashboard;

{
  /**
   * <label>Mr.Jhon</label>
                      <ProgressBar
                        className="first-progress-bar"
                        variant="fifth-bar"
                        now={45}
                        label={`45%`}
                      />
                      <label className="mt-3">Mr.Jhon</label>
                      <ProgressBar
                        className="second-progress-bar"
                        variant="first-bar"
                        now={60}
                        label={`60%`}
                      />
                      <label className="mt-3">Mr.Kahn</label>
                      <ProgressBar
                        className="third-progress-bar"
                        variant="second-bar"
                        now={40}
                        label={`40%`}
                      />
                      <label className="mt-3">Mr.Roy</label>
                      <ProgressBar
                        className="fourth-progress-bar"
                        variant="third-bar"
                        now={20}
                        label={`20%`}
                      />
                      <label className="mt-3">Mr.Y</label>
                      <ProgressBar
                        className="fifth-progress-bar"
                        variant="fourth-bar"
                        now={80}
                        label={`80%`}
                      />
                      <label className="mt-3">Mr.Roy</label>
                      <ProgressBar
                        className="first-progress-bar"
                        now={75}
                        label={`75%`}
                      />
                      <label className="mt-3">Mr.Y</label>
                      <ProgressBar variant="warning" now={70} label={`70%`} />
   */
}
