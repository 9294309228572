import React, { useState, useEffect } from "react";
import { Accordion } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import logo from "../../Assets/logo/Logo-01.png";
import dashboardLogo from "../../Assets/icons/Dashboard.png";
import productLogo from "../../Assets/icons/Box.png";
import costCenterLogo from "../../Assets/icons/costCenter.png";
import userLogo from "../../Assets/icons/user (2).png";
import emailLogo from "../../Assets/icons/email.png";
import listLogo from "../../Assets/icons/Ellipse.png";
import selectedLogo from "../../Assets/icons/selected.png";
import "./Sidebar.css";

const Sidebar = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [activeState, setActiveState] = useState();
  const [tabView, setTabView] = useState(false);
  const [windowDimension, detectHW] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const detectSize = () => {
    detectHW({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  };
  const widthChangeFunction = () => {
    if (windowDimension.width <= 990) {
      setTabView(true);
    } else {
      setTabView(false);
    }
  };
  // this will be deleted on production
  useEffect(() => {
    window.addEventListener("resize", detectSize);
    return () => {
      window.removeEventListener("resize", detectSize);
      widthChangeFunction();
    };
  }, [windowDimension]);
  useEffect(() => {
    widthChangeFunction();
  }, [windowDimension]);
  useEffect(() => {
    if (
      location.pathname === "/asset-list" ||
      location.pathname === "/add-asset" ||
      location.pathname === "/scanned-list"
    ) {
      setActiveState("second");
    } else if (
      location.pathname === "/userList" ||
      location.pathname === "/addUser"
    ) {
      setActiveState("third");
    } else if (location.pathname === "/email-management") {
      setActiveState("fourth");
    } else if (location.pathname === "/cost-center") {
      setActiveState("fifth");
    } else {
      setActiveState("first");
    }
  }, []);

  const detectLocation = (pathname) => {
    if (location.pathname === pathname) {
      return "active";
    } else {
      return "";
    }
  };

  return (
    <div
      className={
        tabView === false
          ? "sidebar"
          : props.sideMenu === true && tabView === false
          ? "sidebar"
          : props.sideMenu === true && tabView === true
          ? "sidebar"
          : "sidebar d-none"
      }
    >
      <div className="d-flex justify-content-end m-2 d-lg-none">
        <button
          type="button"
          className="btn-close"
          onClick={() => props.setSideMenu(false)}
        ></button>
      </div>
      <div className="mt-5 mb-5 d-flex justify-content-center">
        <img
          src={logo}
          alt="logo"
          style={{ maxHeight: "50px", cursor: "pointer" }}
          onClick={() => navigate("/")}
        />
      </div>
      {activeState !== undefined ? (
        <Accordion className="me-2 ms-2" defaultActiveKey={activeState}>
          <Accordion.Item className="mb-1 manual-body" eventKey="first">
            <Link to="/" className={detectLocation("/")}>
              <img
                src={dashboardLogo}
                alt="dashboard"
                height={24}
                style={{ paddingRight: "1rem" }}
              />{" "}
              Dashboard
            </Link>
          </Accordion.Item>
          <Accordion.Item eventKey="second">
            <Accordion.Header>
              <img
                className="accordion-img"
                src={productLogo}
                alt="productLogo"
              />
              Asset Management{" "}
            </Accordion.Header>
            <Accordion.Body className="dropdown-body">
              <Link to="/asset-list" className={detectLocation("/asset-list")}>
                {detectLocation("/asset-list") === "active" ? (
                  <img
                    className="list-img"
                    src={selectedLogo}
                    alt="selectedLogo"
                  />
                ) : (
                  <img className="list-img" src={listLogo} alt="listLogo" />
                )}
                Asset List
              </Link>
              <Link
                to="/add-asset"
                className={detectLocation("/add-asset")}
                style={{ paddingTop: "20px" }}
              >
                {detectLocation("/add-asset") === "active" ? (
                  <img
                    className="list-img"
                    src={selectedLogo}
                    alt="selectedLogo"
                  />
                ) : (
                  <img className="list-img" src={listLogo} alt="listLogo" />
                )}
                Add Asset
              </Link>
              <Link
                to="/scanned-list"
                className={detectLocation("/scanned-list")}
                style={{ paddingTop: "20px" }}
              >
                {detectLocation("/scanned-list") === "active" ? (
                  <img
                    className="list-img"
                    src={selectedLogo}
                    alt="selectedLogo"
                  />
                ) : (
                  <img className="list-img" src={listLogo} alt="listLogo" />
                )}
                Scanned List
              </Link>
            </Accordion.Body>
          </Accordion.Item>
          {/**
           * <Accordion.Item className="manual-body" eventKey="second">
            <Link
              to="/inventory-management"
              className={detectLocation("/inventory-management")}
            >
              <img
                src={productLogo}
                alt="productLogo"
                height={24}
                style={{ paddingRight: "1rem" }}
              />{" "}
              Inventory Management
            </Link>
          </Accordion.Item>
           */}
          <Accordion.Item className="d-none manual-body" eventKey="fifth">
            <Link to="/cost-center" className={detectLocation("/cost-center")}>
              <img
                src={costCenterLogo}
                alt="costCenter"
                height={24}
                style={{ paddingRight: "1rem" }}
              />{" "}
              Cost Center
            </Link>
          </Accordion.Item>
          <Accordion.Item eventKey="third">
            <Accordion.Header>
              <img className="accordion-img" src={userLogo} alt="userLogo" />
              User Management{" "}
            </Accordion.Header>
            <Accordion.Body className="dropdown-body">
              <Link to="/userList" className={detectLocation("/userList")}>
                {detectLocation("/userList") === "active" ? (
                  <img
                    className="list-img"
                    src={selectedLogo}
                    alt="selectedLogo"
                  />
                ) : (
                  <img className="list-img" src={listLogo} alt="listLogo" />
                )}
                User List
              </Link>
              <Link
                to="/addUser"
                className={detectLocation("/addUser")}
                style={{ paddingTop: "20px" }}
              >
                {detectLocation("/addUser") === "active" ? (
                  <img
                    className="list-img"
                    src={selectedLogo}
                    alt="selectedLogo"
                  />
                ) : (
                  <img className="list-img" src={listLogo} alt="listLogo" />
                )}
                Add User
              </Link>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item className="manual-body" eventKey="fourth">
            <Link
              to="/email-management"
              className={detectLocation("/email-management")}
            >
              <img
                src={emailLogo}
                alt="email"
                height={24}
                style={{ paddingRight: "1rem" }}
              />{" "}
              Email Management
            </Link>
          </Accordion.Item>
        </Accordion>
      ) : (
        <></>
      )}
    </div>
  );
};

export default Sidebar;

{
  /**
           *           <Accordion.Item eventKey="fourth">
            <Accordion.Header>
              <img className="accordion-img" src={emailLogo} alt="emailLogo" />
              Email Management{" "}
            </Accordion.Header>
            <Accordion.Body className="dropdown-body">
              <Link to="/emailList" className={detectLocation("/emailList")}>
                {detectLocation("/emailList") === "active" ? (
                  <img
                    className="list-img"
                    src={selectedLogo}
                    alt="selectedLogo"
                  />
                ) : (
                  <img className="list-img" src={listLogo} alt="listLogo" />
                )}
                Email List
              </Link>
              <Link
                to="/addEmail"
                className={detectLocation("/addEmail")}
                style={{ paddingTop: "20px" }}
              >
                {detectLocation("/addEmail") === "active" ? (
                  <img
                    className="list-img"
                    src={selectedLogo}
                    alt="selectedLogo"
                  />
                ) : (
                  <img className="list-img" src={listLogo} alt="listLogo" />
                )}
                Add Email
              </Link>
            </Accordion.Body>
          </Accordion.Item>
           */
}
