import {
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGIN_FAILURE,
  USER_LOGOUT,
  USER_LIST_REQUEST,
  USER_LIST_SUCCESS,
  USER_LIST_FAILURE,
  USER_ROLE_LIST_REQUEST,
  USER_ROLE_LIST_SUCCESS,
  USER_ROLE_LIST_FAILURE,
  USER_CREATE_REQUEST,
  USER_CREATE_SUCCESS,
  USER_CREATE_FAILURE,
  USER_DELETE_REQUEST,
  USER_DELETE_SUCCESS,
  USER_DELETE_FAILURE,
  USER_UPDATE_REQUEST,
  USER_UPDATE_SUCCESS,
  USER_UPDATE_FAILURE,
  ALL_USER_LIST_REQUEST,
  ALL_USER_LIST_SUCCESS,
  ALL_USER_LIST_FAILURE,
} from "../Type.js";

export const userLoginReducers = (state = {}, action) => {
  switch (action.type) {
    case USER_LOGIN_REQUEST:
      return { loading: true };
    case USER_LOGIN_SUCCESS:
      return { loading: false, userInfo: action.payload };
    case USER_LOGIN_FAILURE:
      return { loading: false, error: action.payload };
    case USER_LOGOUT:
      return {};
    default:
      return state;
  }
};

export const userListReducers = (state = { userList: {} }, action) => {
  switch (action.type) {
    case USER_LIST_REQUEST:
      return { loading: true, ...state };
    case USER_LIST_SUCCESS:
      return { loading: false, userList: action.payload };
    case USER_LIST_FAILURE:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const createUserReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_CREATE_REQUEST:
      return { loading: true };
    case USER_CREATE_SUCCESS:
      return {
        loading: false,
        message: action.payload,
      };
    case USER_CREATE_FAILURE:
      return {
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const deleteUserReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_DELETE_REQUEST:
      return { loading: true };
    case USER_DELETE_SUCCESS:
      return {
        loading: false,
        message: action.payload,
      };
    case USER_DELETE_FAILURE:
      return {
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const updateUserReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_UPDATE_REQUEST:
      return { loading: true };
    case USER_UPDATE_SUCCESS:
      return {
        loading: false,
        message: action.payload,
      };
    case USER_UPDATE_FAILURE:
      return {
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const userRoleListReducers = (state = { userRoleList: {} }, action) => {
  switch (action.type) {
    case USER_ROLE_LIST_REQUEST:
      return { loading: true, ...state };
    case USER_ROLE_LIST_SUCCESS:
      return { loading: false, userRoleList: action.payload };
    case USER_ROLE_LIST_FAILURE:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const allUserListReducers = (state = { allUserList: {} }, action) => {
  switch (action.type) {
    case ALL_USER_LIST_REQUEST:
      return { getloading: true, ...state };
    case ALL_USER_LIST_SUCCESS:
      return { getloading: false, allUserList: action.payload };
    case ALL_USER_LIST_FAILURE:
      return { getloading: false, allUserListError: action.payload };
    default:
      return state;
  }
};
