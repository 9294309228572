import axios from "axios";
import {
  EMAIL_LIST_REQUEST,
  EMAIL_LIST_SUCCESS,
  EMAIL_LIST_FAILURE,
  CREATE_EMAIL_REQUEST,
  CREATE_EMAIL_SUCCESS,
  CREATE_EMAIL_FAILURE,
  DELETE_EMAIL_REQUEST,
  DELETE_EMAIL_SUCCESS,
  DELETE_EMAIL_FAILURE,
  UPDATE_EMAIL_REQUEST,
  UPDATE_EMAIL_SUCCESS,
  UPDATE_EMAIL_FAILURE,
} from "../Type.js";

export const getEmailList = () => async (dispatch) => {
  try {
    dispatch({
      type: EMAIL_LIST_REQUEST,
    });
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    };
    const data = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/api/email/list/`,
      config
    );

    dispatch({
      type: EMAIL_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: EMAIL_LIST_FAILURE,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const createEmailId = (email, role) => async (dispatch) => {
  try {
    dispatch({
      type: CREATE_EMAIL_REQUEST,
    });
    const body = {
      email: email,
      role: role,
    };
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    };
    const { data } = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/api/email/create/`,
      body,
      config
    );

    dispatch({
      type: CREATE_EMAIL_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: CREATE_EMAIL_FAILURE,
      payload:
        error.response && error.response.data
          ? error.response.data
          : error.message,
    });
  }
};

export const deleteEmailId = (email) => async (dispatch) => {
  try {
    dispatch({
      type: DELETE_EMAIL_REQUEST,
    });

    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        "Content-Type": "application/json",
      },
    };
    const { data } = await axios.delete(
      `${process.env.REACT_APP_BASE_URL}/api/email/EmailDeatilsApiView/${email}/`,
      config
    );
    dispatch({
      type: DELETE_EMAIL_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: DELETE_EMAIL_FAILURE,
      payload:
        error.response && error.response.data
          ? error.response.data
          : error.message,
    });
  }
};

export const updateEmailId =
  (email, updatedEmail, role) => async (dispatch) => {
    try {
      dispatch({
        type: UPDATE_EMAIL_REQUEST,
      });
      const body = {
        email: updatedEmail,
        role: role,
      };
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      };
      const { data } = await axios.put(
        `${process.env.REACT_APP_BASE_URL}/api/email/EmailDeatilsApiView/${email}/`,
        body,
        config
      );

      dispatch({
        type: UPDATE_EMAIL_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: UPDATE_EMAIL_FAILURE,
        payload:
          error.response && error.response.data
            ? error.response.data
            : error.message,
      });
    }
  };
