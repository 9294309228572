import React from "react";
import copyrightIcon from "../../Assets/icons/copyright.png";
import "./Footer.css";

const Footer = () => {
  return (
    <footer>
      <p className="footer-text text-center" style={{ color: "#DCE0E4" }}>
        Powered By | SmartMatrix PTE LTD.
      </p>
    </footer>
  );
};
export default Footer;
