import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Sidebar from "../Sidebar/Sidebar";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import { createProductAction } from "../../Redux/actions/InventroyAction";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Container, Row, Col, Form, Button, Spinner } from "react-bootstrap";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { toast, ToastContainer } from "react-toastify";
import "./Inventory.css";

const AddAsset = () => {
  const dispatch = useDispatch();
  const [alertMessage, setAlertMessage] = useState(false);
  const [assetId, setAssetId] = useState("");
  const [subNumber, setSubNumber] = useState("");
  const [inventoryNumber, setInventoryNumber] = useState("");
  const [costCenter, setCostCenter] = useState("");
  const [assetName, setAssetName] = useState("");
  const [department, setDepartment] = useState("");
  const [sideMenu, setSideMenu] = useState(true);
  const { loading, message, error } = useSelector(
    (state) => state.createProduct
  );

  useEffect(() => {
    if (alertMessage && message === undefined && error !== undefined) {
      if (error.message) {
        alert(error.message);
        setAlertMessage(false);
      } else if (error?.non_field_errors) {
        alert(error?.non_field_errors);
        setAlertMessage(false);
      } else if (error.asset) {
        alert(error.asset);
        setAlertMessage(false);
      } else {
        alert(error);
        setAlertMessage(false);
      }
    }
    if (alertMessage && message !== undefined) {
      setAssetId("");
      setSubNumber("");
      setInventoryNumber("");
      setCostCenter("");
      setDepartment("");
      setAssetName("");
      successAlert();
      setAlertMessage(false);
    }
  }, [message, error]);
  const handleCreateAsset = (e) => {
    e.preventDefault();
    dispatch(
      createProductAction(
        inventoryNumber,
        assetId,
        subNumber,
        costCenter,
        assetName,
        department
      )
    );
    setAlertMessage(true);
  };
  const successAlert = () => {
    toast.info("New Asset Created Successfully", {
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  };
  const handleCancelButton = (e) => {
    e.preventDefault();
    setAssetId("");
    setSubNumber("");
    setInventoryNumber("");
    setCostCenter("");
    setDepartment("");
    setAssetName("");
  };
  //console.log(inventoryNumber);
  //console.log(error);
  //console.log(message);
  return (
    <div>
      <Sidebar setSideMenu={setSideMenu} sideMenu={sideMenu}></Sidebar>
      <div className="mainContent">
        <ToastContainer />
        <Header setSideMenu={setSideMenu} sideMenu={sideMenu}></Header>
        <Container fluid>
          <Row className="ms-2 me-2">
            <Col>
              <h4 className="product-heading">Add Asset</h4>
              <p className="product-paragraph">
                {" "}
                Asset Management{" "}
                <FontAwesomeIcon icon={faChevronRight} size="xs" /> Add Asset
              </p>
            </Col>
          </Row>
          <div className="mb-4 card product-card container-view">
            <Form className="p-4" onSubmit={handleCreateAsset}>
              <Row>
                <Col lg={6}>
                  <Form.Group
                    className="form-group mb-3"
                    controlId="formBasicEmail"
                  >
                    <Form.Label>
                      Asset ID{" "}
                      <span style={{ color: "#c70a5f", fontSize: "19px" }}>
                        *
                      </span>
                    </Form.Label>
                    <Form.Control
                      value={assetId}
                      onChange={(e) => setAssetId(e.target.value)}
                      type="number"
                      placeholder="e.g:101010000001"
                      required
                    />
                  </Form.Group>
                </Col>
                <Col lg={6}>
                  <Form.Group
                    className="form-group mb-3"
                    controlId="formBasicEmail"
                  >
                    <Form.Label>
                      Sub Number{" "}
                      <span style={{ color: "#c70a5f", fontSize: "19px" }}>
                        *
                      </span>
                    </Form.Label>
                    <Form.Control
                      type="number"
                      value={subNumber}
                      onChange={(e) => setSubNumber(e.target.value)}
                      placeholder="e.g:0000"
                      required
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col lg={4}>
                  <Form.Group
                    className="form-group mb-3"
                    controlId="formBasicEmail"
                  >
                    <Form.Label>Inventory Number</Form.Label>
                    <Form.Control
                      type="text"
                      value={inventoryNumber}
                      onChange={(e) => setInventoryNumber(e.target.value)}
                      placeholder="e.g:TSDP-0000000029"
                    />
                  </Form.Group>
                </Col>
                <Col lg={4}>
                  <Form.Group
                    className="form-group mb-3"
                    controlId="formBasicEmail"
                  >
                    <Form.Label>
                      Cost Center{" "}
                      <span style={{ color: "#c70a5f", fontSize: "19px" }}>
                        *
                      </span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      value={costCenter}
                      onChange={(e) => setCostCenter(e.target.value)}
                      placeholder="e.g:SWKS-B60TR"
                      required
                    />
                  </Form.Group>
                </Col>
                <Col lg={4}>
                  <Form.Group
                    className="form-group mb-3"
                    controlId="formBasicEmail"
                  >
                    <Form.Label>
                      Department{" "}
                      <span style={{ color: "#c70a5f", fontSize: "19px" }}>
                        *
                      </span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      value={department}
                      onChange={(e) => setDepartment(e.target.value)}
                      placeholder="e.g:N-WSP                      "
                      required
                      //style={{ height: "70px" }}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col lg={12}>
                  <Form.Group
                    className="form-group mb-3"
                    controlId="formBasicEmail"
                  >
                    <Form.Label>
                      Asset Name{" "}
                      <span style={{ color: "#c70a5f", fontSize: "19px" }}>
                        *
                      </span>
                    </Form.Label>
                    <Form.Control
                      as="textarea"
                      value={assetName}
                      onChange={(e) => setAssetName(e.target.value)}
                      rows={3}
                      placeholder="e.g:FREEHOLD UPPER THOMSON ROAD (MK15 LOT2180W)"
                      required
                      //style={{ height: "70px" }}
                    />
                  </Form.Group>
                </Col>
              </Row>
              {loading === true ? (
                <Row className="mt-2 d-flex justify-content-center">
                  <Spinner animation="border" variant="primary" />
                </Row>
              ) : (
                <></>
              )}
              <Row className="mt-5">
                <Col xl={2} lg={6} className="mb-2 mb-lg-0">
                  <Button type="submit" className="submit-btn border-0">
                    Submit
                  </Button>
                </Col>
                <Col xl={2} lg={6}>
                  <Button
                    className="cancel-btn border-0"
                    onClick={handleCancelButton}
                  >
                    Cancel
                  </Button>
                </Col>
              </Row>
            </Form>
          </div>
        </Container>
      </div>
      <Footer></Footer>
    </div>
  );
};

export default AddAsset;
