import {
  DASHBOARD_CALCULATION_REQUEST,
  DASHBOARD_CALCULATION_SUCCESS,
  DASHBOARD_CALCULATION_FAILURE,
  GET_USER_PROFILE_REQUEST,
  GET_USER_PROFILE_SUCCESS,
  GET_USER_PROFILE_FAILURE,
} from "../Type.js";

export const getDashboardCalculationReducers = (
  state = { calculation: {} },
  action
) => {
  switch (action.type) {
    case DASHBOARD_CALCULATION_REQUEST:
      return { loading: true, ...state };
    case DASHBOARD_CALCULATION_SUCCESS:
      return { loading: false, calculation: action.payload };
    case DASHBOARD_CALCULATION_FAILURE:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const getUserProfileReducers = (state = { profile: {} }, action) => {
  switch (action.type) {
    case GET_USER_PROFILE_REQUEST:
      return { loading: true, ...state };
    case GET_USER_PROFILE_SUCCESS:
      return { loading: false, profile: action.payload };
    case GET_USER_PROFILE_FAILURE:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
