import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Container,
  Navbar,
  Nav,
  Col,
  Row,
  Dropdown,
  Button,
  ButtonGroup,
  NavDropdown,
} from "react-bootstrap";
import { logout } from "../../Redux/actions/UserAction";
import { getUserProfile } from "../../Redux/actions/DashboardAction";
import userIcon from "../../Assets/icons/user-profile-icon.png";
import pubLogo from "../../Assets/logo/Logo-03.png";
import "./Header.css";

const Header = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [date, setDate] = useState(new Date());
  const { loading, profile, error } = useSelector((state) => state.userProfile);
  useEffect(() => {
    dispatch(getUserProfile());
  }, [dispatch]);

  useEffect(() => {
    var timer = setInterval(() => setDate(new Date()), 1000);
    return function cleanup() {
      clearInterval(timer);
    };
  }, []);

  const handleLogout = () => {
    dispatch(logout());
    navigate("/login");
  };

  return (
    <>
      <Navbar className="main-content-header">
        <Container fluid className="container-view">
          <Navbar.Brand className="d-lg-none">
            <img
              src={pubLogo}
              alt="pubLogo"
              //height={40}
              className="brand-logo"
              onClick={() => props.setSideMenu(true)}
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse
            id="basic-navbar-nav"
            className="justify-content-end"
          >
            <Nav>
              <Nav.Item
                className="p-2 p-md-3"
                style={{ borderRight: "2px solid #DCE0E4" }}
              >
                {" "}
                <Col>
                  <Row>
                    <span style={{ fontWeight: "600" }}>
                      {date.toLocaleTimeString()}
                    </span>
                  </Row>
                  <Row style={{}}>
                    <span style={{ fontWeight: "500" }}>
                      {date.toLocaleDateString()}
                    </span>{" "}
                  </Row>
                </Col>
              </Nav.Item>
              <Nav.Item className="p-md-2 d-flex justify-content-center align-items-center">
                {" "}
                <img
                  src={userIcon}
                  alt="user"
                  //height={65}
                  className="user-icon"
                  style={{ paddingLeft: "1rem" }}
                />{" "}
                {/* <span
                  className="d-sm-none"
                  style={{
                    fontWeight: "600",
                    paddingLeft: "0.4rem",
                    paddingRight: "0rem",
                  }}
                >
                  {profile?.data?.username !== undefined
                    ? profile.data.username
                    : "Admin User"}
                  <Dropdown as={ButtonGroup}>
                    <Dropdown.Toggle
                      split
                      className="bg-white border-0"
                      variant=""
                      id="dropdown-split-basic"
                    />

                    <Dropdown.Menu className="dropdown-menu-end">
                      <Dropdown.Item onClick={handleLogout}>
                        Log 0ut
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </span> */}
              </Nav.Item>
              <Nav.Item
                className="d-flex justify-content-center align-items-center"
                style={{ paddingRight: "2.5rem" }}
              >
                <NavDropdown
                  //id="nav-dropdown-dark-example"
                  style={{
                    fontWeight: "600",
                    fontSize: "20px",
                    //color: "#212529",
                    //paddingLeft: "0.4rem",
                    //paddingRight: "0rem",
                  }}
                  title={
                    profile?.data?.username !== undefined
                      ? profile.data.username
                      : "Admin User"
                  }
                  //menuVariant="dark"
                >
                  <NavDropdown.Item onClick={handleLogout}>
                    Log 0ut
                  </NavDropdown.Item>
                </NavDropdown>
              </Nav.Item>
              {/* <span
                  style={{
                    fontWeight: "600",
                    paddingLeft: "0.4rem",
                    paddingRight: "0rem",
                  }}
                >
                  Admin User
                  <Dropdown as={ButtonGroup}>
                    <Dropdown.Toggle
                      split
                      className="bg-white border-0"
                      variant=""
                      id="dropdown-split-basic"
                    />

                    <Dropdown.Menu className="dropdown-menu-end">
                      <Dropdown.Item onClick={handleLogout}>
                        Log 0ut
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </span> */}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
};

export default Header;
