import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { logout } from "../../Redux/actions/UserAction";
import { getEmailList, createEmailId } from "../../Redux/actions/EmailAction";
import { toast, ToastContainer } from "react-toastify";
import Sidebar from "../Sidebar/Sidebar";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import UpdateEmailModal from "../PopupModal/UpdateEmailModal";
import DeleteModal from "../PopupModal/DeleteModal";
import {
  Container,
  Row,
  Col,
  Table,
  Form,
  Button,
  Spinner,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronRight,
  faPenAlt,
  faTrashCan,
  faAdd,
} from "@fortawesome/free-solid-svg-icons";
import pdfIcon from "../../Assets/icons/pdf.png";
import excelIcon from "../../Assets/icons/excel.png";
import txtIcon from "../../Assets/icons/TXT.png";
import filter from "../../Assets/icons/search.png";
import "../InventoryManagement/Inventory.css";
import generatePDF from "../../services/pdfGenerator";
import { CSVLink } from "react-csv";

const EmailManagement = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [modalShow, setModalShow] = useState(false);
  const [email, setEmail] = useState("");
  const [role, setRole] = useState("");
  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const [selectedEmail, setSelectedEmail] = useState("");
  const [successMsg, setSuccessMsg] = useState(false);
  const [searchVal, setSearchVal] = useState("");
  const [searchResult, setSearchResult] = useState("");
  const [sideMenu, setSideMenu] = useState(true);
  const { getloading, emailList, getListError } = useSelector(
    (state) => state.getEmailList
  );
  const { loading, message, error } = useSelector((state) => state.createEmail);

  useEffect(() => {
    dispatch(getEmailList());
  }, [dispatch, message]);
  useEffect(() => {
    if (searchVal !== "" && emailList !== {}) {
      const results = emailList?.data.filter(
        (d) =>
          d.id?.toString().toLowerCase().includes(searchVal.toLowerCase()) ||
          d.email?.toLowerCase().includes(searchVal.toLowerCase()) ||
          d.role?.toLowerCase().includes(searchVal.toLowerCase())
      );
      setSearchResult(results);
    }
  }, [searchVal]);
  useEffect(() => {
    if (getListError?.code === "token_not_valid") {
      errorAlert();
      dispatch(logout());
      navigate(0);
    }
    if (successMsg && message === undefined && error !== undefined) {
      if (error.email) {
        alert("This Email Id Already Exists");
        setSuccessMsg(false);
      } else if (error.role) {
        alert(error.role);
        setSuccessMsg(false);
      } else {
        alert(error);
        setSuccessMsg(false);
      }
    }
    if (successMsg && message !== undefined) {
      setEmail("");
      setRole("");
      successAlert();
      setSuccessMsg(false);
    }
  }, [message, error, getListError]);
  const errorAlert = () => {
    toast.error("Your Login Session Expired", {
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  };
  const successAlert = () => {
    toast.info("New Email-Id Created Successfully", {
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  };
  const handleAddEmail = (e) => {
    e.preventDefault();
    dispatch(createEmailId(email, role));
    setSuccessMsg(true);
  };
  const handleClickDelete = (email) => {
    setSelectedEmail(email);
    setDeleteModalShow(true);
  };

  const handleClickUpdateEmail = (email) => {
    setSelectedEmail(email);
    setModalShow(true);
  };

  const downloadTextFile = (array) => {
    if (array === undefined && getloading === false) {
      alert("No Data Found");
    } else if (array === undefined && getloading === true) {
      alert("Data is not ready for download . Try Again after a minute");
    } else {
      const element = document.createElement("a");
      const file = new Blob(
        [
          "\t \t \t PUB EMAIL LIST \n \n" +
            array
              .map((arr) => arr.id + "\t" + arr.email + "\t" + "\t" + arr.role)
              .join("\n"),
        ],
        {
          type: "text/plain",
        }
      );
      element.href = URL.createObjectURL(file);
      element.download = "pub_email_list.txt";
      document.body.appendChild(element); // Required for this to work in FireFox
      element.click();
    }
  };

  const headers = [
    { label: "ID", key: "id" },
    { label: "Email", key: "email" },
    { label: "Role", key: "role" },
  ];
  const csvReport = {
    data: emailList?.data,
    headers: headers,
    filename: "PUB_EMAIL_LIST.csv",
  };
  //console.log(emailList?.data);
  //console.log(getListError);

  return (
    <div>
      <UpdateEmailModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        backdrop="static"
        keyboard={false}
        useremail={selectedEmail}
      />
      <DeleteModal
        show={deleteModalShow}
        onHide={() => setDeleteModalShow(false)}
        useremail={selectedEmail.email}
        onAction={() => setSearchVal("")}
      />
      <Sidebar setSideMenu={setSideMenu} sideMenu={sideMenu}></Sidebar>
      <div className="mainContent">
        <ToastContainer />
        <Header setSideMenu={setSideMenu} sideMenu={sideMenu}></Header>
        <Container fluid>
          <Row className="ms-2 me-2">
            <Col lg={6}>
              <h4 className="product-heading">Email List</h4>
              <p className="product-paragraph">
                {" "}
                Email Management{" "}
                <FontAwesomeIcon icon={faChevronRight} size="xs" /> Email List
              </p>
            </Col>
          </Row>
          <div
            className="mb-4 p-3 card product-card container-view"
            style={{ minHeight: "50vh" }}
          >
            <Row className="mb-4">
              <Col lg={6} md={8}>
                <img src={filter} alt="filter" height={45} />
                <input
                  className="search-bar"
                  type="search"
                  placeholder="Search"
                  aria-label="Search"
                  value={searchVal}
                  onChange={(e) => setSearchVal(e.target.value)}
                />
              </Col>
              <Col lg={6} md={4} className="d-flex justify-content-end">
                <img
                  onClick={() => downloadTextFile(emailList?.data)}
                  className="pe-3"
                  src={txtIcon}
                  alt="pdf"
                  height={45}
                  style={{ cursor: "pointer" }}
                />
                <img
                  onClick={() =>
                    generatePDF(
                      emailList?.data,
                      "Email",
                      ["ID", "Email", "Role"],
                      "PUB Email List",
                      emailList?.data?.length
                    )
                  }
                  className="pe-3"
                  src={pdfIcon}
                  alt="pdf"
                  height={45}
                  style={{ cursor: "pointer" }}
                />
                {emailList !== {} && emailList?.data !== undefined ? (
                  <CSVLink
                    {...csvReport}
                    //className="csv-report-btn"
                    target="_blank"
                  >
                    <img src={excelIcon} alt="pdf" height={45} />
                  </CSVLink>
                ) : (
                  <img
                    src={excelIcon}
                    alt="pdf"
                    height={45}
                    onClick={() => alert("No Data Found")}
                    style={{ cursor: "pointer" }}
                  />
                )}
              </Col>
            </Row>
            <Row>
              <Col xl={4} className="mb-3">
                <h3 className="category-heading">Add Email</h3>
                <Form className="pt-4 pe-3" onSubmit={handleAddEmail}>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>
                      Email Id{" "}
                      <span style={{ color: "#c70a5f", fontSize: "19px" }}>
                        *
                      </span>
                    </Form.Label>
                    <Form.Control
                      required
                      type="email"
                      placeholder="demo@email.com"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </Form.Group>
                  <Form.Group
                    className=" form-group mb-5"
                    controlId="formBasicEmail"
                  >
                    <Form.Label>
                      User Role{" "}
                      <span style={{ color: "#c70a5f", fontSize: "19px" }}>
                        *
                      </span>
                    </Form.Label>
                    <Form.Control
                      required
                      type="text"
                      placeholder="e.g:admin/sales"
                      value={role}
                      onChange={(e) => setRole(e.target.value)}
                    />
                    {/**<Form.Select aria-label="Default select example">
                      <option value=".." selected disabled>
                        Select Role
                      </option>
                      <option value="1">A</option>
                      <option value="2">Sales</option>
                      <option value="2">Account</option>
                    </Form.Select> */}
                  </Form.Group>
                  {loading === true ? (
                    <Row className="mb-4 d-flex justify-content-center">
                      <Spinner animation="border" variant="primary" />
                    </Row>
                  ) : (
                    <></>
                  )}

                  <Button className="add-email-btn border-0" type="submit">
                    <FontAwesomeIcon
                      icon={faAdd}
                      style={{ paddingRight: "0.4rem" }}
                    />
                    Add Email
                  </Button>
                </Form>
              </Col>
              <Col xl={8}>
                {emailList !== {} ? (
                  <Table responsive hover>
                    <thead className="text-center table-header">
                      <tr>
                        <th>SL No.</th>
                        <th>Email</th>
                        <th>Role</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody className="text-center">
                      {emailList?.data?.length !== undefined &&
                      searchVal === "" ? (
                        emailList?.data?.map((email, index) => (
                          <tr key={index}>
                            <td>{email.id}</td>
                            <td>{email.email}</td>
                            <td>{email.role}</td>
                            <td style={{ cursor: "pointer" }}>
                              <FontAwesomeIcon
                                //className="d-none"
                                icon={faPenAlt}
                                color="#637381"
                                onClick={() => handleClickUpdateEmail(email)}
                              />
                              <FontAwesomeIcon
                                icon={faTrashCan}
                                color="#E40000"
                                style={{ paddingLeft: "0.6rem" }}
                                onClick={() => handleClickDelete(email)}
                              />
                            </td>
                          </tr>
                        ))
                      ) : searchVal !== "" && searchResult.length !== 0 ? (
                        searchResult?.map((email, index) => (
                          <tr key={index}>
                            <td>{email.id}</td>
                            <td>{email.email}</td>
                            <td>{email.role}</td>
                            <td style={{ cursor: "pointer" }}>
                              <FontAwesomeIcon
                                //className="d-none"
                                icon={faPenAlt}
                                color="#637381"
                                onClick={() => handleClickUpdateEmail(email)}
                              />
                              <FontAwesomeIcon
                                icon={faTrashCan}
                                color="#E40000"
                                style={{ paddingLeft: "0.6rem" }}
                                onClick={() => handleClickDelete(email)}
                              />
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="8">No Results Found</td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                ) : (
                  <span>Loading ....</span>
                )}
              </Col>
            </Row>
          </div>
        </Container>
      </div>
      <Footer></Footer>
    </div>
  );
};

export default EmailManagement;
