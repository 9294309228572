import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Sidebar from "../Sidebar/Sidebar";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import {
  Container,
  Row,
  Col,
  Table,
  Spinner,
  Modal,
  OverlayTrigger,
  Tooltip,
  Button,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import pdfIcon from "../../Assets/icons/pdf.png";
import excelIcon from "../../Assets/icons/excel.png";
import filter from "../../Assets/icons//search.png";
import txtIcon from "../../Assets/icons/TXT.png";
import "./Inventory.css";
import { useSelector, useDispatch } from "react-redux";
import { logout } from "../../Redux/actions/UserAction";
import {
  getScannedProductList,
  getAllScannedProductList,
  deleteAllScannedProductAction,
} from "../../Redux/actions/InventroyAction.js";
import ReactPaginate from "react-paginate";
import generatePDF from "../../services/pdfGenerator";
import { CSVLink } from "react-csv";
import axios from "axios";
import XLSX from "xlsx";
import { toast, ToastContainer } from "react-toastify";
import DeleteModal from "../PopupModal/DeleteModal";

const ScannedList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchVal, setSearchVal] = useState("");
  const [searchResult, setSearchResult] = useState("");
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [scannedList, setScannedList] = useState([]);
  const [exportData, setExportData] = useState(false);
  const [sideMenu, setSideMenu] = useState(true);
  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const [deleteAllData, setDeleteAllData] = useState(false);
  const [alertTxt, setAlertTxt] = useState(false);
  const [selectedScanAsset, setSelectedScanAsset] = useState("");
  const [allIds, setAllIds] = useState([]);
  const { loading, scannedProductList, getListError } = useSelector(
    (state) => state.getScannedProducts
  );
  const { getloading, allScannedProductList, allScannedProductListError } =
    useSelector((state) => state.getAllScannedProducts);
  const allListDelete = useSelector((state) => state.deleteAllScanProduct);
  useEffect(() => {
    dispatch(getScannedProductList(limit, offset));
  }, [dispatch, offset]);

  useEffect(() => {
    if (scannedProductList?.data?.count) {
      setTotalItems(scannedProductList?.data?.count);
      //console.log("----", scannedProductList?.data?.count);
      //console.log("----", totalItems);
      // if (totalItems === scannedProductList?.data?.count) {

      // }
    }
  }, [scannedProductList]);
  useEffect(() => {
    if (totalItems === scannedProductList?.data?.count) {
      dispatch(getAllScannedProductList(totalItems));
      console.log("inside");
    }
  }, [totalItems]);
  useEffect(() => {
    if (getListError?.code === "token_not_valid") {
      errorAlert();
      dispatch(logout());
      navigate(0);
      //console.log("inside");
    }
  }, [getListError]);
  const errorAlert = () => {
    toast.error("Your Login Session Expired", {
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  };
  // useEffect(() => {
  //   if (totalItems !== 0 && totalItems !== scannedList.length) {
  //     //console.log(totalItems);
  //     getAllScannedProducts();
  //   }
  // }, [totalItems]);
  //console.log(getListError);
  //console.log(scannedList);
  useEffect(() => {
    if (searchVal.length !== 0 && allScannedProductList !== {}) {
      const results = allScannedProductList?.data?.results?.filter(
        (d) =>
          d.asset?.toString().toLowerCase().includes(searchVal.toLowerCase()) ||
          d.inventory_number?.toLowerCase().includes(searchVal.toLowerCase())
        // d.name?.toLowerCase().includes(searchVal) ||
        // d.cost_center?.toLowerCase().includes(searchVal) ||
        // d.sub_number?.toLowerCase().includes(searchVal) ||
        // d.send_by?.toLowerCase().includes(searchVal) ||
        // d.created_at?.toLowerCase().includes(searchVal)
      );
      setSearchResult(results);
    } else {
      //dispatch(getProductList(limit, 0));
      setSearchResult([]);
    }
  }, [searchVal]);
  useEffect(() => {
    if (allListDelete.message !== undefined && alertTxt === true) {
      deleteScanAssetSuccessAlert();
      dispatch(getScannedProductList(10, 0));
      setAlertTxt(false);
      setDeleteAllData(false);
      setTotalItems(0);
      //props.onAction();
    }
    if (allListDelete.error !== undefined && alertTxt === true) {
      setAlertTxt(false);
      alert("Something Went Wrong.Try Again Later");
    }
  }, [allListDelete]);
  const deleteScanAssetSuccessAlert = () => {
    toast.info("All Scanned Asset Deleted Successfully", {
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  };
  const handleSearchValueChange = (e) => {
    setSearchVal(e.target.value);
  };

  const getScannedProductFromApi = (type) => {
    setExportData(true);
    try {
      //const form_data = new FormData();
      //form_data.append("csv_file", filefield);
      //const body = data;
      const settings = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      };
      //console.log("inside Api Call");
      const api = `${process.env.REACT_APP_BASE_URL}/api/product/list/?client=web&limit=${totalItems}&offset=0`;

      axios
        .get(api, settings)
        .then((res) => {
          //console.log("success", res.data.results);
          //setScannedList((prevData) => [...prevData, ...res.data.results]);
          if (type === "text") {
            downloadTextFile(res.data.results);
          } else if (type === "pdf") {
            setExportData(false);
            generatePDF(
              res.data.results,
              "Scanned",
              [
                "Asset ID",
                "Sub-Number",
                "Inventory Number",
                "Cost Center",
                "Asset Name",
                "Department",
                "Scanned By",
                "Scanned Date",
              ],
              "PUB Scanned Asset List",
              totalItems
            );
          } else if (type === "excel") {
            const test = [];
            res.data.results.map((d) => {
              let obj = {};
              obj["Asset"] = d.asset;
              obj["Sub Number"] = d.sub_number;
              obj["Inventory Number"] = d.inventory_number;
              obj["Cost Center"] = d.cost_center;
              obj["Name"] = d.name;
              obj["Department"] = d.department;
              obj["Scanned By"] = d.send_by;
              test.push(obj);
            });
            //console.log(test);
            handleExport(test);
          }
          //setImportAssetModal(false);
          //successAlert();
          //dispatch(getProductList());
          //setFilefield(null);
        })
        .catch((errror) => {
          console.log("error", errror);
          setExportData(false);
          alert("Something Went Wrong,Please Try Again After a minute");
        });
    } catch {
      console.log("error");
      setExportData(false);
      alert("Something Went Wrong,Please Try Again After a minute");
    }
  };
  const getAllScannedProducts = () => {
    let offsetValue = 0;
    const totalCall = Math.ceil(totalItems / 500);
    for (let i = 0; i < totalCall; i++) {
      //console.log("inside");
      getScannedProductFromApi(500, offsetValue);
      //await dispatch(getAllProductList(100, offsetValue));
      //setOffsetCall(100 + offsetCall);
      offsetValue = offsetValue + 500;
      //console.log(offsetValue);
    }
    //console.log(offsetCall);
    //console.log(limitCall);
  };

  const pageCount = Math.ceil(totalItems / limit);

  const handlePageClick = (event) => {
    // pub newOffset = (event.selecter * limit) % count
    // dispatch(api(limit , offset))
    const newOffset = (event.selected * limit) % totalItems;
    // console.log(
    //   `User requested page number ${event.selected}, which is offset ${newOffset}`
    // );
    setOffset(newOffset);
  };

  const downloadTextFile = (array) => {
    if (array.length === 0) {
      alert("No Data Found");
      setExportData(false);
    } else if (array.length !== totalItems) {
      alert("Data is not ready for download . Try Again after a minute");
      setExportData(false);
    } else {
      const element = document.createElement("a");
      const file = new Blob(
        [
          "\t \t \t PUB SCANNED ASSET LIST \n \n" +
            array
              .map(
                (arr) =>
                  arr.asset +
                  "\t" +
                  arr.sub_number +
                  "\t" +
                  arr.inventory_number +
                  "\t" +
                  "\t" +
                  "\t" +
                  arr.cost_center +
                  "\t" +
                  arr.name +
                  "\t" +
                  arr.department +
                  "\t" +
                  arr.send_by
              )
              .join("\n"),
        ],
        {
          type: "text/plain",
        }
      );
      element.href = URL.createObjectURL(file);
      element.download = "pub_scanned_list.txt";
      document.body.appendChild(element); // Required for this to work in FireFox
      element.click();
      setExportData(false);
    }
  };
  const handleExport = (data) => {
    // const dataSet = [
    //   data[0].asset,
    //   data[0].sub_number,
    //   data[0].inventory_number,
    //   data[0].cost_center,
    //   data[0].name,
    //   data[0].send_by,
    // ];
    // console.log(data[0]);
    // const t = data[0];
    const sheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, sheet, "Sheet1");
    XLSX.writeFile(workbook, "PUB_SCANNED_ASSET_LIST.xlsx");
    setExportData(false);
  };
  const headers = [
    { label: "Asset Id", key: "asset" },
    { label: "Sub-Number", key: "sub_number" },
    { label: "Inventory-Number", key: "inventory_number" },
    { label: "Cost Center", key: "cost_center" },
    { label: "Asset Name", key: "name" },
    { label: "Scanned By", key: "send_by" },
  ];
  const csvReport = {
    data: scannedList,
    headers: headers,
    filename: "PUB_SCANNED_ASSET_LIST.csv",
  };
  var Ids = [];
  const allScanAssetsIds = () => {
    if (totalItems !== 0) {
      setDeleteAllData(true);
      //dispatch(getAllScannedProductList(totalItems));
    } else {
      alert("No Data Found");
    }
  };
  //console.log(Ids);
  const handleClickDelete = (scanData) => {
    setSelectedScanAsset(scanData);
    setDeleteModalShow(true);
  };
  const handleDeleteAllData = () => {
    allScannedProductList?.data?.results.map((scan) => {
      Ids.push(scan.id);
    });
    //console.log("inside", Ids);
    dispatch(deleteAllScannedProductAction(Ids));
    setAlertTxt(true);
    setDeleteModalShow(false);
  };
  //console.log(getloading);
  //console.log(searchVal.length);
  return (
    <div>
      <Modal
        backdrop="static"
        keyboard={false}
        show={exportData}
        onHide={() => setExportData(false)}
      >
        {/**<Modal.Header className="modal-customize-header" closeButton></Modal.Header> */}
        <Modal.Body>
          {exportData === true ? (
            <Row className="mt-2 d-flex justify-content-center">
              <Spinner animation="border" variant="primary" />
              <p className="mt-3 text-center">
                <b>Data is Dowloading.Please Wait for a moment......</b>
              </p>
            </Row>
          ) : (
            <></>
          )}
        </Modal.Body>
      </Modal>
      <Modal show={deleteAllData} onHide={() => setDeleteAllData(false)}>
        {getloading === true ? (
          <Modal.Body className="p-4">
            <Row className="mt-2 d-flex justify-content-center">
              <Spinner animation="border" variant="primary" />
              <p className="mt-3 text-center">
                <b>Data is Loading.Please Wait for a moment......</b>
              </p>
            </Row>
          </Modal.Body>
        ) : (
          <Modal.Body className="p-4">
            <Row className="d-flex justify-content-center">
              All Scan Data will be deleted Permanently
            </Row>
            <Row className="d-flex justify-content-center">Are you sure??</Row>
            <Row className="mt-3">
              <div className="d-flex justify-content-center">
                <Button variant="danger" onClick={handleDeleteAllData}>
                  Delete
                </Button>
                <Button
                  className="ms-3"
                  variant="secondary"
                  onClick={() => setDeleteAllData(false)}
                >
                  Cancel
                </Button>
              </div>
            </Row>
          </Modal.Body>
        )}
      </Modal>
      <DeleteModal
        show={deleteModalShow}
        onHide={() => setDeleteModalShow(false)}
        scanasset={selectedScanAsset}
        offset={offset}
        onAction={() => setSearchVal("")}
      />
      <Sidebar setSideMenu={setSideMenu} sideMenu={sideMenu}></Sidebar>
      <div className="mainContent">
        <ToastContainer />
        <Header setSideMenu={setSideMenu} sideMenu={sideMenu}></Header>
        <Container fluid>
          <Row className="ms-2 me-2">
            <Col lg={6}>
              <h4 className="product-heading">Scanned List</h4>
              <p className="product-paragraph">
                {" "}
                Asset Management{" "}
                <FontAwesomeIcon icon={faChevronRight} size="xs" /> Scanned List
              </p>
            </Col>
          </Row>
          <div
            className="mb-4 p-3 card product-card container-view"
            style={{ maxHeight: "700px" }}
          >
            <Row className="mb-4">
              {/* <Col lg={6} className="d-none">
                <img src={filter} alt="filter" height={45} />
                <input
                  className="search-bar"
                  type="search"
                  value={searchVal}
                  onChange={(e) => handleSearchValueChange(e)}
                  placeholder="Search"
                  aria-label="Search"
                />
              </Col> */}
              <Col md={8} className="mb-2 mb-lg-0">
                <img src={filter} alt="filter" height={45} />
                <input
                  className="scan-search-bar"
                  type="search"
                  value={searchVal}
                  onChange={(e) => handleSearchValueChange(e)}
                  placeholder="Search By Asset Id or Inventory Number"
                  aria-label="Search"
                />
                <button
                  className="mb-2 mt-2 mb-lg-0 ms-2 pe-3 ps-3 border-0 delete-product-button"
                  onClick={() => allScanAssetsIds()}
                >
                  <FontAwesomeIcon
                    icon={faTrashCan}
                    style={{ paddingRight: "0.8rem" }}
                  />
                  Delete All
                </button>
              </Col>
              <Col md={4} className="d-flex justify-content-md-end">
                <OverlayTrigger
                  key="export1"
                  placement="top"
                  overlay={<Tooltip id="tooltip-top">Export as a .txt</Tooltip>}
                >
                  <img
                    className="pe-3"
                    src={txtIcon}
                    alt="txt"
                    height={45}
                    onClick={() => getScannedProductFromApi("text")}
                    style={{ cursor: "pointer" }}
                  />
                </OverlayTrigger>
                <OverlayTrigger
                  key="export2"
                  placement="top"
                  overlay={<Tooltip id="tooltip-top">Export as a pdf</Tooltip>}
                >
                  <img
                    className="pe-3"
                    src={pdfIcon}
                    alt="pdf"
                    height={45}
                    onClick={() => getScannedProductFromApi("pdf")}
                    style={{ cursor: "pointer" }}
                  />
                </OverlayTrigger>
                <OverlayTrigger
                  key="export3"
                  placement="top"
                  overlay={
                    <Tooltip id="tooltip-top">Export as a excel</Tooltip>
                  }
                >
                  <img
                    src={excelIcon}
                    alt="pdf"
                    height={45}
                    onClick={() => getScannedProductFromApi("excel")}
                    style={{ cursor: "pointer" }}
                  />
                </OverlayTrigger>
              </Col>
            </Row>
            <Row className="">
              <p
                className="text-center"
                style={{ fontWeight: "700", color: "#0075bf" }}
              >
                Total Scanned Assets: {totalItems}
              </p>
            </Row>
            <Table responsive hover>
              <thead className="text-center table-header">
                <tr>
                  <th>Asset Id</th>
                  <th>Sub-Number</th>
                  <th>Inventory Number</th>
                  <th>Cost Center</th>
                  <th>Asset Name</th>
                  <th>Department</th>
                  <th>Scanned By</th>
                  <th>Scanned Date</th>
                  <th>Email Status</th>
                  <th>Tag Type</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody className="text-center">
                {searchVal === "" &&
                scannedProductList !== {} &&
                scannedProductList?.data !== undefined &&
                scannedProductList?.data.results.length !== 0 ? (
                  scannedProductList?.data?.results.map((list) => (
                    <tr key={list.id}>
                      <td>{list.asset}</td>
                      <td>{list.sub_number}</td>
                      <td>{list.inventory_number}</td>
                      <td>{list.cost_center}</td>
                      <td>{list.name}</td>
                      <td>{list.department}</td>
                      <td>{list.send_by}</td>
                      <td>{list.created_at.split("T")[0]}</td>
                      <td>
                        {list.email_sent_status === "send" ? (
                          <button
                            className="border-0"
                            style={{
                              background: "#1C449C",
                              color: "white",
                              fontSize: "14px",
                              borderRadius: "5px",
                              width: "80px",
                              cursor: "not-allowed",
                            }}
                          >
                            Sent
                          </button>
                        ) : (
                          <button
                            className="border-0"
                            style={{
                              background: "rgba(0, 117, 191, 0.1)",
                              color: "#1B2850",
                              fontSize: "14px",
                              borderRadius: "5px",
                              width: "80px",
                              cursor: "not-allowed",
                              //opacity: "0",
                            }}
                          >
                            Pending
                          </button>
                        )}
                      </td>
                      <td>{list.tag_type}</td>
                      <td style={{ cursor: "pointer" }}>
                        <OverlayTrigger
                          key="delete"
                          placement="top"
                          overlay={<Tooltip id="tooltip-top">Delete</Tooltip>}
                        >
                          <FontAwesomeIcon
                            icon={faTrashCan}
                            color="#E40000"
                            onClick={() => handleClickDelete(list)}
                            //style={{ paddingLeft: "0.8rem" }}
                          />
                        </OverlayTrigger>
                      </td>
                    </tr>
                  ))
                ) : searchVal.length !== 0 && searchResult?.length !== 0 ? (
                  searchResult?.map((list) => (
                    <tr key={list.id}>
                      <td>{list.asset}</td>
                      <td>{list.sub_number}</td>
                      <td>{list.inventory_number}</td>
                      <td>{list.cost_center}</td>
                      <td>{list.name}</td>
                      <td>{list.department}</td>
                      <td>{list.send_by}</td>
                      <td>{list.created_at.split("T")[0]}</td>
                      <td>
                        {list.email_sent_status === "send" ? (
                          <button
                            className="border-0"
                            style={{
                              background: "#1C449C",
                              color: "white",
                              fontSize: "14px",
                              borderRadius: "5px",
                              width: "80px",
                              cursor: "not-allowed",
                            }}
                          >
                            Send
                          </button>
                        ) : (
                          <button
                            className="border-0"
                            style={{
                              background: "rgba(0, 117, 191, 0.1)",
                              color: "#1B2850",
                              fontSize: "14px",
                              borderRadius: "5px",
                              width: "80px",
                              cursor: "not-allowed",
                              //opacity: "0",
                            }}
                          >
                            Pending
                          </button>
                        )}
                      </td>
                      <td>{list.tag_type}</td>
                      <td style={{ cursor: "pointer" }}>
                        <OverlayTrigger
                          key="delete"
                          placement="top"
                          overlay={<Tooltip id="tooltip-top">Delete</Tooltip>}
                        >
                          <FontAwesomeIcon
                            icon={faTrashCan}
                            color="#E40000"
                            onClick={() => handleClickDelete(list)}
                            //style={{ paddingLeft: "0.8rem" }}
                          />
                        </OverlayTrigger>
                      </td>
                    </tr>
                  ))
                ) : loading === true ? (
                  <tr>
                    <td colSpan="12">
                      <Spinner animation="border" variant="primary" />
                    </td>
                  </tr>
                ) : getloading === true && searchVal.length !== 0 ? (
                  <tr>
                    <td colSpan="12">
                      <Spinner animation="border" variant="primary" />
                    </td>
                  </tr>
                ) : (
                  <tr>
                    <td colSpan="12">No Result Found</td>
                  </tr>
                )}
              </tbody>
            </Table>
            {pageCount > 1 && searchVal.length === 0 ? (
              <ReactPaginate
                breakLabel="..."
                nextLabel=""
                onPageChange={handlePageClick}
                pageRangeDisplayed={3}
                marginPagesDisplayed={1}
                pageCount={pageCount}
                previousLabel=""
                renderOnZeroPageCount={null}
                containerClassName="pagination"
                pageLinkClassName="page-num"
                previousClassName="hide"
                nextClassName="hide"
                activeLinkClassName="active"
              />
            ) : (
              <></>
            )}
          </div>
        </Container>
      </div>
      <Footer></Footer>
    </div>
  );
};

export default ScannedList;
